import axios from "axios";
import { get } from "lodash";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
    REQUEST_MASTER_PROMOCODE,
    REQUEST_CREATE_MASTER_PROMOCODE,
    REQUEST_UPDATE_MASTER_PROMOCODE,
    SET_MASTER_PROMOCODE,
    SUCCESS_CREATE_MASTER_PROMOCODE,
    SUCCESS_UPDATE_MASTER_PROMOCODE,
    ERROR_GET_MASTER_PROMOCODE,
    ERROR_CREATE_MASTER_PROMOCODE,
    ERROR_UPDATE_MASTER_PROMOCODE,
} from "./promoCodeActionTypes"

function* requestMasterPromoCode(action: Record<string, any>): any {
    try {
        const result: any = yield call(getPromoCodeMaster);
        yield put({ type: SET_MASTER_PROMOCODE, payload: result.data });
    } catch (error: any) {
        console.log(error);
        let message =
            "Something went wrong, please try again after some time or Refresh the Page.";
        if (get(error, "response.status") === 500) {
            message = "Something happened wrong try again after sometime.";
        } else if (get(error, "response.status") === 422) {
            message = error.response.data.message || "please provide valid contain";
        } else if (get(error, "response.status") === 415) {
            message = error.response.data.message;
        }
        yield put({ type: ERROR_GET_MASTER_PROMOCODE, payload: message });
    }
}

function* requestUpdateMasterPromoCode(action: Record<string, any>): any {
    try {
        const result = yield call(
            updateMasterPromoCode,
            action.data.payload,
            action.data._id
        )
        yield put({
            type: SUCCESS_UPDATE_MASTER_PROMOCODE,
            payload: result.data,
        });
    } catch (error: any) {
        console.log(error);
        let message =
            "Something went wrong, please try again after some time or Refresh the Page.";
        if (get(error, "response.status") === 500) {
            message = "Something happened wrong try again after sometime.";
        } else if (get(error, "response.status") === 422) {
            message = error.response.data.message || "please provide valid contain";
        } else if (get(error, "response.status") === 415) {
            message = error.response.data.message;
        }
        yield put({ type: ERROR_UPDATE_MASTER_PROMOCODE, payload: message });
    }
}

function* requestCreateMasterPromoCode(action: Record<string, any>): any {
    try {
        const result = yield call(createMasterPromoCode, action.payload);
        yield put({
            type: SUCCESS_CREATE_MASTER_PROMOCODE,
            payload: result.data,
        });
        // update relevant category as well
    } catch (error: any) {
        console.log(error);
        let message =
            "Something went wrong, please try again after some time or Refresh the Page.";
        if (get(error, "response.status") === 500) {
            message = "Something happened wrong try again after sometime.";
        } else if (get(error, "response.status") === 422) {
            message = error.response.data.message || "please provide valid contain";
        } else if (get(error, "response.status") === 415) {
            message = error.response.data.message;
        }
        yield put({ type: ERROR_CREATE_MASTER_PROMOCODE, payload: message });
    }
}


export function getPromoCodeMaster() {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/stripe/promocde/`,
        withCredentials: true,
    });
}

export function updateMasterPromoCode(payload: Record<string, any>, _id: string) {
    return axios({
        method: "patch",
        url: `${process.env.REACT_APP_BASE_URL}/stripe/promocde/${_id}`,
        data: payload,
        withCredentials: true,
    });
}

export function createMasterPromoCode(payload: Record<string, any>) {
    return axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/stripe/promocde/`,
        data: payload,
        withCredentials: true,
    });
}


const promoCodeMasterSaga = function* () {
    yield all([
        takeLatest(REQUEST_MASTER_PROMOCODE, requestMasterPromoCode),
        takeLatest(REQUEST_UPDATE_MASTER_PROMOCODE, requestUpdateMasterPromoCode),
        takeLatest(REQUEST_CREATE_MASTER_PROMOCODE, requestCreateMasterPromoCode),

    ])
}

export default promoCodeMasterSaga;