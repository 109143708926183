import axios from "axios";
import { Fragment } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import AuthenticatedRoute from "./componenets/Auth/AuthenticatedRoute";
import Sidebar from "./componenets/Header/Sidebar";
import Login from "./pages/Login/Login";
import Accounts from "./pages/Accounts/Accounts";
import User from "./pages/User/User";
import LinksMaster from "./pages/LinksMaster/LinksMaster";
import { get } from "lodash";
import Feedback from "./pages/Feedback/Feedback";
import LinksCategoryMaster from "./pages/LinksCategoryMaster/LinksCategoryMasterTable";
import Products from "./pages/Products/Products";
import Coupons from "./pages/Coupons/Coupons";
import PromoCode from "./pages/PromoCode/PromoCode";
// import MobileProducts from "./pages/MobileProducts/MobileProducts";
// import MobileCoupons from "./pages/MobileCoupons/MobileCoupons";
import Tutorial from "./pages/Tutorial/Tutorial";
import Faq from "./pages/Faq/Faq";
import Tags from "./pages/Tag/Tag";
import "antd/dist/antd.min.css";
import Report from "./pages/Report/Report";
import Analytics from "./pages/Analytics/Analytics";
import Instruction from "./pages/Instruction/Instruction";

const App = () => {
  axios.interceptors.request.use(
    (config: any) => {
      config.headers.authorization = localStorage.getItem("token");
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (config) => {
      return config;
    },
    (error) => {
      if (get(error, "response.status") === 403) {
        localStorage.clear();
        window.location.href = "/login";
      }
      return Promise.reject(error);
    }
  );

  return (
    <Router>
      <Fragment>
        <Routes>
          <Route
            path="/login"
            element={
              <>
                <Login />
              </>
            }
          ></Route>
          <Route
            path="/"
            element={
              <>
                <Sidebar />
                <AuthenticatedRoute />
              </>
            }
          >
            <Route path="/" element={<Accounts />}></Route>
            {/* <Route path="/links" element={<Links />}></Route> */}
            <Route path="/links" element={<LinksMaster />}></Route>
            <Route path="/user" element={<User />}></Route>
            <Route path="/report" element={<Report />}></Route>
            <Route path="/messageCenter" element={<Feedback />}></Route>
            <Route path="/products" element={<Products />}></Route>
            <Route path="/coupon" element={<Coupons />}></Route>
            <Route path="/promcode" element={<PromoCode />}></Route>
            {/*   <Route path="/mobileproducts" element={<MobileProducts />}></Route>
            <Route path="/mobilecoupons" element={<MobileCoupons />}></Route> */}
            <Route path="/tutorial" element={<Tutorial />}></Route>
            <Route path="/faq" element={<Faq />}></Route>
            <Route path="/tag" element={<Tags />}></Route>
            <Route path="/analytics" element={<Analytics />}></Route>
            <Route path="/instruction" element={<Instruction />}></Route>
            <Route
              path="/links_category"
              element={<LinksCategoryMaster />}
            ></Route>
            {/* <Route path="/user" element={<User />}></Route> */}
            <Route path="*" element={<Navigate replace to="/" />} />
          </Route>
        </Routes>
      </Fragment>
    </Router>
  );
};

export default App;
