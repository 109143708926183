import { get } from "lodash";
import { useSelector } from "react-redux";
import { IUser } from "../../types/user";
import {
  REQUEST_DELETEUSER,
  SET_DELETEUSER,
  REQUEST_DELETE_DELETEUSER,
  SUCCESS_DELETE_DELETEUSER,
  ERROR_DELETE_DELETEUSER,
  REQUEST_RECOVER_DELETEUSER,
  SUCCESS_RECOVER_DELETEUSER,
  ERROR_RECOVER_DELETEUSER,
  ERROR_DELETEUSER,
} from "./deleteUserAction";

const initialState = {
  busy: false,
  message: "",
  deleteusers: [],
};
const deleteusers = (state = initialState, action: Record<string, any>) => {
  switch (action.type) {
    case REQUEST_DELETEUSER:
    case REQUEST_DELETE_DELETEUSER:
    case REQUEST_RECOVER_DELETEUSER:
      return {
        ...state,
        busy: true,
        message: "",
      };
    case SET_DELETEUSER:
      return {
        ...state,
        busy: false,
        message: "",
        deleteusers: action.payload,
      };

    case SUCCESS_DELETE_DELETEUSER:
      return {
        ...state,
        busy: false,
        message: "",
        deleteusers: state.deleteusers.filter(
          (user) => get(user, "_id") !== action.payload
        ),
      };

    case SUCCESS_RECOVER_DELETEUSER:
      return {
        ...state,
        busy: false,
        message: "",
        deleteusers: state.deleteusers.filter(
          (user) => get(user, "_id") !== action.payload
        ),
      };

    case ERROR_DELETEUSER:
    case ERROR_DELETE_DELETEUSER:
    case ERROR_RECOVER_DELETEUSER:
      return {
        ...state,
        busy: false,
        message:
          action.payload || "Something happened wrong try again after sometime",
      };
    default:
      return state;
  }
};
export default deleteusers;

export function useDeleteUsers() {
  return useSelector((state: Record<string, any>) => state.deleteusers);
}
