import React, { useEffect, useMemo, useState } from "react";
import { Button, IconButton, Text, useDisclosure } from "@chakra-ui/react";
import { get as _get } from "lodash";
import { IFeedBack } from "../../types/feedback";
import { useFeedback } from "../../store/feedback/reducer";
import { useDispatch } from "react-redux";
import {
  ERROR_SEND_ADMIN_FEEDBACK,
  REQUEST_FEEDBACK_LIST,
} from "../../store/feedback/feedbackActionType";
import { IFeedBackType } from "../../types/IFeedBackType";
import FeedbackReplyDrawer from "../drawer/FeedbackReplyDrawer";
import { Spin, Table } from "antd";
import { IoMdLogIn } from "react-icons/io";
import { getAuthToken } from "../../script/getAuthToken";
import { BiArchiveIn, BiArchiveOut } from "react-icons/bi";
import { TiTick, TiTickOutline } from "react-icons/ti";
import ArchiveFeedbackConformation from "../modals/ArchiveFeedBackConformation";
import CompleteFeedBackConformation from "../modals/CompleteFeedBackConformation";
import DeleteFeedBackConformation from "../modals/DeleteFeedBackConformation";
import { MdDelete } from "react-icons/md";

interface Props {
  searchQuery: string;
  responseType: string;
  responseMessageType: IFeedBackType;
  isCompleted: boolean;
  isArchived: boolean;
}

const FeedbackTable: React.FC<Props> = ({
  searchQuery: query,
  responseType,
  responseMessageType,
  isCompleted,
  isArchived,
}) => {
  const dispatch = useDispatch();

  const {
    feedback,
    busy,
    message,
  }: {
    busy: boolean;
    message: string;
    feedback: IFeedBack[];
    replySend: boolean;
  } = useFeedback();

  const [targetFeedback, setTargetFeedback] = useState<IFeedBack>();

  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();
  const viewFeedback = (feedback: IFeedBack) => {
    setTargetFeedback(feedback);
    onViewOpen();
  };

  const {
    isOpen: isCompleteOpen,
    onOpen: onCompleteOpen,
    onClose: onCompleteClose,
  } = useDisclosure();
  const completeFeedback = (feedback: IFeedBack) => {
    setTargetFeedback(feedback);
    onCompleteOpen();
  };

  const {
    isOpen: isArchiveOpen,
    onOpen: onArchiveOpen,
    onClose: onArchiveClose,
  } = useDisclosure();
  const archiveFeedback = (feedback: IFeedBack) => {
    setTargetFeedback(feedback);
    onArchiveOpen();
  };

  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const deleteFeedback = (feedback: IFeedBack) => {
    setTargetFeedback(feedback);
    onDeleteOpen();
  };

  useEffect(() => {
    if (!feedback.length && !busy && !message) {
      dispatch({ type: REQUEST_FEEDBACK_LIST });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchColumns = useMemo(
    () => [
      "accountId.firstName",
      "accountId.lastName",
      "accountId.companyName",
      "userId.email",
      "email",
      "message",
      "messageType.title",
      "subject",
      "responseType",
    ],
    []
  );

  const search = (feedback: IFeedBack) => {
    return (
      searchColumns.some((column) => {
        return (
          _get(feedback, column, "")
            .toString()
            .toLowerCase()
            .indexOf(query.toLowerCase()) > -1
        );
      }) &&
      (!responseType.length ||
        (responseType === "TRUE" && feedback.responseType) ||
        (responseType === "FALSE" && !feedback.responseType)) &&
      (!responseMessageType.title.trim() ||
        responseMessageType.title === _get(feedback, "messageType.title"))
    );
  };

  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setData(
      feedback
        .filter((item) => item.isCompleted === isCompleted)
        .filter((item) => item.isArchived === isArchived)
        .filter(search)
        .map((value: IFeedBack, index: number) => ({
          _id: value._id,
          key: index + 1,
          Index: index + 1,
          email: value.email,
          title: _get(value, "messageType.title"),
          subject: value.subject,
          responseType: value.responseType ? "true" : "false",
          message:
            _get(value, "message").length > 20
              ? _get(value, "message").slice(0, 20) + "..."
              : value.message,
          value: value,
          color: value.isDeleted ? "red" : "gray",
        }))
    );
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    feedback,
    query,
    responseType,
    responseMessageType,
    isCompleted,
    isArchived,
  ]);
  const columns = [
    {
      title: "SR N0.",
      dataIndex: "Index",
      width: 80,
    },
    {
      title: "ACTION",
      dataIndex: "value",
      width: 300,
      render: (value: IFeedBack) => (
        <>
          <Button
            onClick={() => {
              dispatch({
                type: ERROR_SEND_ADMIN_FEEDBACK,
                payload: "none",
              });
              viewFeedback(value);
            }}
          >
            View
          </Button>
          {_get(value, "userId._id") ? (
            <IconButton
              ml={2}
              aria-label="View Message"
              icon={<IoMdLogIn color="#3e8578" />}
              onClick={() => {
                getAuthToken(_get(value, "userId._id"));
              }}
            />
          ) : (
            <IconButton
              ml={2}
              aria-label="View Message"
              icon={<IoMdLogIn color="#3e8578" />}
              disabled={true}
            />
          )}
          <IconButton
            aria-label="Complete"
            ml={1}
            color={value.isCompleted ? "green" : "gray"}
            disabled={value.isDeleted}
            variant={"ghost"}
            icon={value.isCompleted ? <TiTick /> : <TiTickOutline />}
            onClick={() => {
              completeFeedback(value);
            }}
          />

          <IconButton
            aria-label="archive"
            ml={1}
            disabled={value.isDeleted}
            variant={"ghost"}
            icon={value.isArchived ? <BiArchiveOut /> : <BiArchiveIn />}
            onClick={() => {
              archiveFeedback(value);
            }}
          />
          <IconButton
            aria-label="delete"
            ml={1}
            disabled={value.isDeleted}
            variant={"ghost"}
            color={value.isDeleted ? "black" : "red"}
            icon={<MdDelete />}
            onClick={() => {
              deleteFeedback(value);
            }}
          />
        </>
      ),
    },

    {
      title: "NAME",
      dataIndex: "value",
      render: (value: any) => (
        <>
          <Text>
            {_get(value, "accountId.firstName") ||
            _get(value, "accountId.firstName") !== undefined
              ? _get(value, "accountId.firstName") +
                " " +
                _get(value, "accountId.lastName")
              : _get(value, "accountId.BusinessName") ||
                _get(value, "accountId.BusinessName") !== undefined
              ? _get(value, "accountId.BusinessName")
              : "N/A"}
          </Text>
        </>
      ),
    },
    {
      title: "EMAIL",
      dataIndex: "value",
      render: (value: any) => (
        <>
          <Text
          //  color={_get(value, "userId.email") ? "black" : "red"}
          >
            {_get(value, "userId.email")
              ? _get(value, "userId.email")
              : value.email}
          </Text>
        </>
      ),
    },
    {
      title: "MESSAGE TYPE",
      dataIndex: "title",
    },
    {
      title: "SUBJECT",
      dataIndex: "subject",
    },
    {
      title: "MESSAGE",
      dataIndex: "message",
    },
    {
      title: "RESPONSE TYPE",
      dataIndex: "responseType",
    },
    {
      title: "DATE",
      dataIndex: "value",
      render: (value: any) => (
        <>
          <Text>{new Date(value.createdAt).toLocaleString()}</Text>
        </>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          width: "92vw",
          maxWidth: "92vw",
          height: "90vh",
          top: "8vh ",
          left: "4vw",
          position: "relative",
        }}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <Table
            rowKey={(record) => record._id}
            columns={columns}
            dataSource={data}
            loading={busy && feedback.length === 0 ? true : false}
            scroll={{ y: "calc(60vh - 4em)", x: 1400 }}
            rowClassName={(record: any) => record.color}
          />
        )}
      </div>
      {isViewOpen && targetFeedback && (
        <FeedbackReplyDrawer
          isOpen={isViewOpen}
          onClose={onViewClose}
          feedbacks={targetFeedback}
        />
      )}
      {isCompleteOpen && targetFeedback && (
        <CompleteFeedBackConformation
          isOpen={isCompleteOpen}
          onClose={onCompleteClose}
          feedback={targetFeedback}
        />
      )}
      {isArchiveOpen && targetFeedback && (
        <ArchiveFeedbackConformation
          feedback={targetFeedback}
          isOpen={isArchiveOpen}
          onClose={onArchiveClose}
        />
      )}

      {isDeleteOpen && targetFeedback && (
        <DeleteFeedBackConformation
          feedback={targetFeedback}
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
        />
      )}
    </>
  );
};

export default FeedbackTable;
