export const REQUEST_MASTER_MOBILE_PRODUCT = "REQUEST_MASTER_MOBILE_PRODUCT";
export const SET_MASTER_MOBILE_PRODUCT = "SET_MASTER_MOBILE_PRODUCT";
export const ERROR_GET_MASTER_MOBILE_PRODUCT = "ERROR_GET_MASTER_MOBILE_PRODUCT";

export const REQUEST_CREATE_MASTER_MOBILE_PRODUCT = "REQUEST_CREATE_MASTER_MOBILE_PRODUCT";
export const SUCCESS_CREATE_MASTER_MOBILE_PRODUCT = "SUCCESS_CREATE_MASTER_MOBILE_PRODUCT";
export const ERROR_CREATE_MASTER_MOBILE_PRODUCT = "ERROR_CREATE_MASTER_MOBILE_PRODUCT";

export const REQUEST_UPDATE_MASTER_MOBILE_PRODUCT = "REQUEST_UPDATE_MASTER_MOBILE_PRODUCT";
export const SUCCESS_UPDATE_MASTER_MOBILE_PRODUCT = "SUCCESS_UPDATE_MASTER_MOBILE_PRODUCT";
export const ERROR_UPDATE_MASTER_MOBILE_PRODUCT = "ERROR_UPDATE_MASTER_MOBILE_PRODUCT";

export const SET_MESSAGE_MASTER_MOBILE_PRODUCT = "SET_MESSAGE_MASTER_MOBILE_PRODUCT";