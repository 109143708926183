export const REQUEST_MASTER_TAG = "REQUEST_MASTER_TAG";
export const SET_MASTER_TAG = "SET_MASTER_TAG";
export const ERROR_GET_MASTER_TAG = "ERROR_GET_MASTER_TAG";

export const REQUEST_SORTED_TAG = "REQUEST_SORTED_TAG";
export const SET_SORTED_TAG = "SET_SORTED_TAG";
export const ERROR_GET_SORTED_TAG = "ERROR_GET_SORTED_TAG";

export const REQUEST_CREATE_MASTER_TAG = "REQUEST_CREATE_MASTER_TAG";
export const SUCCESS_CREATE_MASTER_TAG = "SUCCESS_CREATE_MASTER_TAG";
export const ERROR_CREATE_MASTER_TAG = "ERROR_CREATE_MASTER_TAG";

export const REQUEST_DELETE_MASTER_TAG = "REQUEST_DELETE_MASTER_TAG";
export const SUCCESS_DELETE_MASTER_TAG = "SUCCESS_DELETE_MASTER_TAG";
export const ERROR_DELETE_MASTER_TAG = "ERROR_DELETE_MASTER_TAG";

export const SET_MESSAGE_MASTER_TAG = "SET_MESSAGE_MASTER_TAG";
