import axios from "axios";
import { get } from "lodash";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { REQUEST_UPDATE_ACCOUNT } from "../account/accountActionType";
import {
  REQUEST_DELETEUSER,
  SET_DELETEUSER,
  REQUEST_DELETE_DELETEUSER,
  SUCCESS_DELETE_DELETEUSER,
  ERROR_DELETE_DELETEUSER,
  SUCCESS_RECOVER_DELETEUSER,
  ERROR_RECOVER_DELETEUSER,
  REQUEST_RECOVER_DELETEUSER,
  ERROR_DELETEUSER,
} from "./deleteUserAction";
function* requestUsers(action: Record<string, any>): any {
  try {
    const result: any = yield call(geUserMaster);
    yield put({ type: SET_DELETEUSER, payload: result.data });
  } catch (error: any) {
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_DELETEUSER, payload: message });
  }
}

function* requestDeleteMasterUser(action: Record<string, any>): any {
  try {
    yield call(deleteMasterUser, get(action, "payload._id", ""));
    yield put({
      type: SUCCESS_DELETE_DELETEUSER,
      payload: get(action, "payload._id", ""),
    });
    yield put({
      type: REQUEST_UPDATE_ACCOUNT,
      payload: get(action, "payload._id", ""),
    });
  } catch (error: any) {
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_DELETE_DELETEUSER, payload: message });
  }
}

function* requestRecoverMasterUser(action: Record<string, any>): any {
  try {
    yield call(recoverMasterUser, get(action, "payload._id", ""));
    yield put({
      type: SUCCESS_RECOVER_DELETEUSER,
      payload: get(action, "payload._id", ""),
    });
    yield put({
      type: REQUEST_UPDATE_ACCOUNT,
      payload: get(action, "payload._id", ""),
    });
  } catch (error: any) {
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_RECOVER_DELETEUSER, payload: message });
  }
}

export function geUserMaster() {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/user/deleteUser`,
    withCredentials: true,
  });
}

export function deleteMasterUser(_id: string) {
  return axios({
    method: "delete",
    url: `${process.env.REACT_APP_BASE_URL}/user/deleteUser/${_id}`,
    withCredentials: true,
  });
}

export function recoverMasterUser(_id: string) {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL}/user/recover/${_id}`,
    withCredentials: true,
  });
}

const deleteUserSaga = function* () {
  yield all([
    takeLatest(REQUEST_DELETEUSER, requestUsers),
    takeLatest(REQUEST_DELETE_DELETEUSER, requestDeleteMasterUser),
    takeLatest(REQUEST_RECOVER_DELETEUSER, requestRecoverMasterUser),
  ]);
};
export default deleteUserSaga;
