import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  CloseButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTagsMaster } from "../../store/tag/reducer";
import { REQUEST_DELETE_MASTER_TAG } from "../../store/tag/tagActionTypes";
interface Props {
  tag: any;
  isOpen: any;
  onClose: any;
}
const TagDeleteModal: React.FC<Props> = ({ isOpen, onClose, tag }) => {
  const dispatch = useDispatch();
  const { masterTags, busy, message } = useTagsMaster();
  const { _id } = tag;
  const toast = useToast();

  useEffect(() => {
    const findLink = masterTags.find(
      (val: Record<string, any>) => val._id === _id
    );
    if (!findLink) {
      toast({
        title: "Deleted",
        description: "Tag is successfully deleted.",
        status: "info",
        isClosable: true,
        position: "top-right",
      });
      onClose();
    }
  }, [_id, masterTags, onClose, toast]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Tag</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontWeight="bold" mb="1rem">
            Are you sure to delete tag?
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme="red"
            variant={"outline"}
            mr={3}
            disabled={busy}
            onClick={() => {
              dispatch({
                type: REQUEST_DELETE_MASTER_TAG,
                payload: { _id: [tag._id] },
              });
            }}
          >
            Submit
          </Button>
        </ModalFooter>{" "}
        {message && (
          <ModalFooter>
            <Alert status="error">
              <AlertIcon />
              <AlertTitle mr={2}>{message}</AlertTitle>
              <CloseButton position="absolute" right="8px" top="8px" />
            </Alert>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
export default TagDeleteModal;
