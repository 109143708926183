import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Spacer,
  Switch,
  Text,
  Tooltip as ChakraTooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Spin, Table } from "antd";
import { useDispatch } from "react-redux";
import moment from "moment";
import DeleteTagPerDelete from "../modals/DeleteTagPerDelete";
import DeleteTagRecover from "../modals/DeleteTagRecover";
import { useDeleteTags } from "../../store/deleteTag/reducer";
import {
  REQUEST_DELETETAG,
  REQUEST_SORTED_DELETETAG,
} from "../../store/deleteTag/deleteTagAction";
import { ITag } from "../../types/tags";
import { CSVLink } from "react-csv";
import { Form, Formik } from "formik";
import { FormField } from "../form/formField/FormField";
import { BrowserView, MobileView } from "react-device-detect";
import style from "./TagMasterTable.module.css";
import Select from "react-select";

// export interface User {
//   searchQuery: string;
//   setCSVData: any;
//   startDate: any;
// }

const TagDeletedTable = ({ setDeletedTag }: any) => {
  const { deletetags, busy } = useDeleteTags();
  const [deleteData, setDeleteData] = useState<any>();
  const [isSearchData, setIsSearchData] = useState(false);
  const [csvData, setCSVData] = useState<any>([]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: REQUEST_DELETETAG });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const deleteFunction = (dataDelete: any) => {
    setDeleteData(dataDelete);
    onDeleteOpen();
  };

  const {
    isOpen: isRecoverOpen,
    onOpen: onRecoverOpen,
    onClose: onRecoverClose,
  } = useDisclosure();

  const recoverFunction = (dataDelete: any) => {
    setDeleteData(dataDelete);
    onRecoverOpen();
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const colorObject: any = useMemo(() => {
    return {
      BK: "Black",
      WT: "White",
      HG: "Hologram",
      QR: "QRDesign",
      MBK: "Matte Black",
      MWT: "Matte White",
      MHG: "Matte Hologram",
    };
  }, []);

  const typeObject: any = useMemo(() => {
    return {
      PC: "PVC Card",
      MC: "Metal Card",
      BMC: "Blank Metal",
      RE: "Round Epoxy",
      SE: "Square Epoxy",
      ST: "SQUARE TAG ( PLASTIC )",
      RT: "ROUND TAG ( PLASTIC )",
    };
  }, []);
  const typeOptions: any[] = useMemo(() => {
    return [
      { value: "PC", label: "PVC CARDS" },
      { value: "MC", label: "METAL CARDS" },
      // { value: "BMC", label: "BLANK METAL CARDS" },
      { value: "RE", label: "ROUND EPOXY" },
      { value: "SE", label: "SQUARE EPOXY" },
      { value: "ST", label: "SQUARE TAG ( PLASTIC )" },
      { value: "RT", label: "ROUND TAG ( PLASTIC )" },
    ];
  }, []);

  const colorOptions: any[] = useMemo(() => {
    return [
      { value: "BK", label: "BLACK" },
      { value: "WT", label: "WHITE" },
      { value: "HG", label: "HOLOGRAM" },
      { value: "MBK", label: "MATTE BLACK" },
      { value: "MWT", label: "MATTE WHITE" },
      { value: "MHG", label: "MATTE HOLOGRAM" },
    ];
  }, []);

  const initialValue = useMemo(() => {
    return {
      size: "",
      type: "",
      color: "",
      batchNo: "",
      isCustomTag: false,
    };
  }, []);
  const toast = useToast();
  const onSubmit = (values: Record<string, any>) => {
    setLoading(true);

    let payload = {
      size: values.size !== "" ? values.size : undefined,
      batchNo: values.batchNo !== "" ? values.batchNo : undefined,
      color: values.color !== null ? values.color.value : undefined,
      type: values.type !== null ? values.type.value : undefined,
      isCustomTag: values.isCustomTag !== null ? values.isCustomTag : false,
      isDeleted: true,
    };

    dispatch({
      type: REQUEST_SORTED_DELETETAG,
      data: payload,
      onSuccess: () => {
        setLoading(false);
        setIsSearchData(true);
      },
    });
  };

  useEffect(() => {
    setData(
      deletetags.map((value: any, index: number) => ({
        _id: value._id,
        key: index + 1,
        index: index + 1,
        color:
          value.color && colorObject[value.color]
            ? colorObject[value.color]
            : "none",
        type:
          value.type && typeObject[value.type]
            ? typeObject[value.type]
            : "none",
        random: value.random,
        batchNo: value.batchNo,
        size: value.size ? value.size : "none",
        urlName: value.urlName,
        block: value.block ? "Blocked" : "Unblocked",
        paired: value.account ? "Paired" : "Unpaired",
        value: value,
      }))
    );
    setLoading(false);
    setCSVData(
      deletetags
        // .filter((item: ITag) => !!item.isCustomTag === isCustomTag)
        .map((value: ITag) => {
          // const data = { ...value };
          // delete data._id;
          // delete data.tagImage;
          // delete data.tagGif;
          // delete data.account;
          // delete data.updatedAt;
          // delete data.password;
          // delete data.label;
          // delete data.block;
          // delete data.size;
          // delete data.color;
          // delete data.batchNo;
          // delete data.type;
          // delete data.random;
          // delete data.blockMessage;

          return {
            serialNumber: value.urlName,
            URL:
              value.createdAt &&
              moment(value.createdAt).isAfter(moment("2023-02-01"))
                ? `${process.env.REACT_APP_FRONTEND_URL}/t/${value.urlName}`
                : `${process.env.REACT_APP_FRONTEND_URL}/${value.urlName}`,
          };
        })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletetags, colorObject, typeObject]);

  const columns = [
    {
      title: "Sr No.",
      dataIndex: "index",
      width: 80,
    },
    {
      title: "ACTION",
      dataIndex: "value",
      width: 340,
      render: (value: any) => (
        <>
          <Button
            // ml={3}
            onClick={() => {
              deleteFunction(value);
            }}
          >
            Permenant Delete
          </Button>
          <Button
            ml={3}
            onClick={() => {
              recoverFunction(value);
            }}
          >
            Recover
          </Button>
        </>
      ),
    },
    {
      title: "Paired",
      dataIndex: "paired",
    },
    {
      title: "Size",
      dataIndex: "size",
    },
    {
      title: "Color",
      dataIndex: "color",
    },
    {
      title: "Batch No.",
      dataIndex: "batchNo",
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Random No.",
      dataIndex: "random",
    },
    {
      title: "Url Name",
      dataIndex: "value",
      render: (value: ITag) => (
        <>
          <Text
            style={{ cursor: "pointer" }}
            onClick={() => {
              // eslint-disable-next-line no-lone-blocks

              const url =
                value.createdAt &&
                moment(value.createdAt).isAfter(moment("2023-02-01"))
                  ? `${process.env.REACT_APP_FRONTEND_URL}/t/${value.urlName}`
                  : `${process.env.REACT_APP_FRONTEND_URL}/${value.urlName}`;

              navigator.clipboard.writeText(url);
            }}
          >
            <ChakraTooltip label={"Click to copy"} fontSize="md">
              {value.urlName}
            </ChakraTooltip>
          </Text>
        </>
      ),
    },
    {
      title: "Block",
      dataIndex: "block",
    },
  ];

  return (
    <>
      <div>
        <Formik
          initialValues={initialValue}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue, errors, touched, submitForm }) => (
            <Form>
              <MobileView>
                <div className={style.main}>
                  <Flex style={{ margin: "20px 30px 0 30px ", gap: "9px" }}>
                    <FormControl style={{ textAlign: "start" }}>
                      <Flex width="100%">
                        <HStack>
                          <FormLabel mb={2}>Type : </FormLabel>
                          <Spacer></Spacer>
                        </HStack>
                      </Flex>

                      <Select
                        value={values["type"]}
                        name="type"
                        isClearable
                        onChange={(event) => {
                          setFieldValue("type", event);
                        }}
                        options={typeOptions}
                        placeholder="Select a Type"
                      />
                    </FormControl>

                    <FormControl style={{ textAlign: "start" }}>
                      <Flex width="100%">
                        <HStack>
                          <FormLabel mb={2}>Color : </FormLabel>
                          <Spacer></Spacer>
                        </HStack>
                      </Flex>

                      <Select
                        value={values["color"]}
                        isClearable
                        name="color"
                        onChange={(event) => {
                          setFieldValue("color", event);
                        }}
                        options={colorOptions}
                        placeholder="Select a color"
                      />
                    </FormControl>
                  </Flex>
                  <div className={style.flex}>
                    <FormField
                      required={false}
                      // disabled={isDisabled}
                      placeholder="Enter Batch No."
                      name="Batch No. : "
                      fieldName="batchNo"
                      type="text"
                      error={
                        touched.batchNo ? (errors.batchNo as string) : undefined
                      }
                    />

                    <FormField
                      required={false}
                      name="Size : "
                      placeholder="Enter Size"
                      fieldName="size"
                      type="text"
                      error={touched.size ? (errors.size as string) : undefined}
                    />
                    <FormControl style={{ textAlign: "start" }}>
                      <Flex width="100%">
                        <HStack>
                          <FormLabel mb={3}>Custom : </FormLabel>
                          <Spacer></Spacer>
                        </HStack>
                      </Flex>

                      <Switch
                        value={values.isCustomTag ? "true" : "false"}
                        name={"ss"}
                        id="email-alerts"
                        defaultChecked={values.isCustomTag}
                        onChange={() => {
                          setFieldValue("isCustomTag", !values.isCustomTag);
                        }}
                      />
                    </FormControl>
                    <div>
                      <button type="submit" className={style.btn}>
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </MobileView>
              <BrowserView>
                <div className={style.flex}>
                  <FormControl style={{ textAlign: "start" }}>
                    <Flex width="100%">
                      <HStack>
                        <FormLabel mb={3}>Type : </FormLabel>
                        <Spacer></Spacer>
                      </HStack>
                    </Flex>

                    <Select
                      value={values["type"]}
                      name="type"
                      isClearable
                      onChange={(event) => {
                        setFieldValue("type", event);
                      }}
                      options={typeOptions}
                      placeholder="Select a Type"
                    />
                  </FormControl>

                  <FormControl style={{ textAlign: "start" }}>
                    <Flex width="100%">
                      <HStack>
                        <FormLabel mb={3}>Color : </FormLabel>
                        <Spacer></Spacer>
                      </HStack>
                    </Flex>

                    <Select
                      value={values["color"]}
                      isClearable
                      name="color"
                      onChange={(event) => {
                        setFieldValue("color", event);
                      }}
                      options={colorOptions}
                      placeholder="Select a color"
                    />
                  </FormControl>

                  <FormField
                    required={false}
                    // disabled={isDisabled}
                    placeholder="Enter Batch No."
                    name="Batch No. : "
                    fieldName="batchNo"
                    type="text"
                    error={
                      touched.batchNo ? (errors.batchNo as string) : undefined
                    }
                  />

                  <FormField
                    required={false}
                    name="Size : "
                    placeholder="Enter Size"
                    fieldName="size"
                    type="text"
                    error={touched.size ? (errors.size as string) : undefined}
                  />

                  <FormControl style={{ textAlign: "start" }}>
                    <Flex width="100%">
                      <HStack>
                        <FormLabel mb={3}>Custom : </FormLabel>
                        <Spacer></Spacer>
                      </HStack>
                    </Flex>

                    <Switch
                      value={values.isCustomTag ? "true" : "false"}
                      name={"ss"}
                      id="email-alerts"
                      defaultChecked={values.isCustomTag}
                      onChange={() => {
                        setFieldValue("isCustomTag", !values.isCustomTag);
                      }}
                    />
                  </FormControl>
                  <div>
                    <button type="submit" className={style.btn}>
                      Search
                    </button>
                  </div>
                </div>
              </BrowserView>
            </Form>
          )}
        </Formik>
        <div className={style.center}>
          <button
            className={style.btn1}
            onClick={() => {
              setLoading(true);
              setIsSearchData(false);
              dispatch({ type: REQUEST_DELETETAG });
            }}
          >
            Show All
          </button>
          <CSVLink data={csvData} filename="tag">
            <button className={style.btn1}>Excel</button>
          </CSVLink>
        </div>
      </div>
      <div
        style={{
          width: "96vw",
          maxWidth: "96vw",
          height: "90vh",
          top: "6vh ",
          left: "2vw",
          position: "relative",
        }}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            loading={busy && deletetags.length === 0 ? true : false}
            scroll={{ y: "calc(68vh - 2em)", x: 1400 }}
            rowClassName={() => "gray"}
            pagination={{
              showSizeChanger: true,
            }}
          />
        )}
      </div>
      {isDeleteOpen && deleteData && (
        <DeleteTagPerDelete
          deletetag={deleteData}
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
        />
      )}
      {isRecoverOpen && deleteData && (
        <DeleteTagRecover
          deletetag={deleteData}
          isOpen={isRecoverOpen}
          onClose={onRecoverClose}
        />
      )}
    </>
  );
};

export default TagDeletedTable;
