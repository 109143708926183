import { get } from "lodash";
import { useSelector } from "react-redux";
import { IUser } from "../../types/user";
import {
  REQUEST_DELETETAG,
  SET_DELETETAG,
  REQUEST_DELETE_DELETETAG,
  SUCCESS_DELETE_DELETETAG,
  ERROR_DELETE_DELETETAG,
  REQUEST_RECOVER_DELETETAG,
  SUCCESS_RECOVER_DELETETAG,
  ERROR_RECOVER_DELETETAG,
  ERROR_DELETETAG,
  REQUEST_SORTED_DELETETAG,
  SET_SORTED_DELETETAG,
  ERROR_GET_SORTED_DELETETAG,
} from "./deleteTagAction";

const initialState = {
  busy: false,
  message: "",
  deletetags: [],
};
const deletetags = (state = initialState, action: Record<string, any>) => {
  switch (action.type) {
    case REQUEST_DELETETAG:
    case REQUEST_DELETE_DELETETAG:
    case REQUEST_RECOVER_DELETETAG:
    case REQUEST_SORTED_DELETETAG:
      return {
        ...state,
        busy: true,
        message: "",
      };
    case SET_DELETETAG:
    case SET_SORTED_DELETETAG:
      return {
        ...state,
        busy: false,
        message: "",
        deletetags: action.payload,
      };

    case SUCCESS_DELETE_DELETETAG:
      return {
        ...state,
        busy: false,
        message: "",
        deletetags: state.deletetags.filter(
          (user) => get(user, "_id") !== action.payload
        ),
      };

    case SUCCESS_RECOVER_DELETETAG:
      return {
        ...state,
        busy: false,
        message: "",
        deletetags: state.deletetags.filter(
          (user) => get(user, "_id") !== action.payload
        ),
      };

    case ERROR_DELETETAG:
    case ERROR_DELETE_DELETETAG:
    case ERROR_RECOVER_DELETETAG:
    case ERROR_GET_SORTED_DELETETAG:
      return {
        ...state,
        busy: false,
        message:
          action.payload || "Something happened wrong try again after sometime",
      };
    default:
      return state;
  }
};
export default deletetags;

export function useDeleteTags() {
  return useSelector((state: Record<string, any>) => state.deletetags);
}
