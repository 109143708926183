import {
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Spacer,
  Switch,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ProductEditDrawer from "../../componenets/drawer/ProductEditDrawer";
import ProductsMasterTable from "../../componenets/ProductsMasterTable/ProductsMasterTable";
import { REQUEST_MASTER_PRODUCT } from "../../store/product/productActionTypes";
import { useProductsMaster } from "../../store/product/reducer";
import { BrowserView, MobileView } from "react-device-detect";

const Products = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const { masterProducts, busy } = useProductsMaster();

  const [liveProduct, setLiveProduct] = useState<boolean>(true);

  useEffect(() => {
    if (!busy && !(masterProducts || []).length) {
      dispatch({ type: REQUEST_MASTER_PRODUCT });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MobileView>
        <VStack top={5} position="relative">
          <Heading size="lg" fontWeight="semibold" color="black.400">
            Products
          </Heading>
          <Spacer></Spacer>
          <Flex style={{ margin: "0 40px" }}>
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="email-alerts" mb="0">
                Live Product
              </FormLabel>
              <Switch
                value={liveProduct.toString()}
                name={"ss"}
                id="email-alerts"
                defaultChecked={liveProduct}
                onChange={(event: any) => {
                  setLiveProduct(!liveProduct);
                }}
              />
            </FormControl>
            <Center
              bg="tomato"
              w="255px"
              h="100%"
              color="white"
              p={3}
              onClick={() => {
                onOpen();
                //   setaddErrorMsg("");
              }}
              style={{ cursor: "pointer" }}
            >
              + Add Product
            </Center>
          </Flex>
        </VStack>
      </MobileView>
      <BrowserView>
        <VStack top={5} position="relative">
          <Flex w="92%">
            <Heading ml="8" size="lg" fontWeight="semibold" color="black.400">
              Products
            </Heading>

            <Spacer></Spacer>
            <Heading size="md" fontWeight="semibold">
              <HStack>
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="email-alerts" mb="0">
                    Live Product
                  </FormLabel>
                  <Switch
                    value={liveProduct.toString()}
                    name={"ss"}
                    id="email-alerts"
                    defaultChecked={liveProduct}
                    onChange={(event: any) => {
                      setLiveProduct(!liveProduct);
                    }}
                  />
                </FormControl>
                <Center
                  bg="tomato"
                  w="255px"
                  h="100%"
                  color="white"
                  p={3}
                  onClick={() => {
                    onOpen();
                    //   setaddErrorMsg("");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  + Add Product
                </Center>
              </HStack>
            </Heading>
          </Flex>
        </VStack>
      </BrowserView>
      <ProductsMasterTable liveProduct={liveProduct} />
      {isOpen && <ProductEditDrawer isOpen onClose={onClose} />}
    </>
  );
};

export default Products;
