import { Button, useDisclosure } from "@chakra-ui/react";
import { Spin, Table } from "antd";

import moment from "moment";
import { useEffect, useState } from "react";
import { useCouponsMaster } from "../../store/coupon/reducer";
import { ICoupons } from "../../types/coupon";
import CouponEditDrawer from "../drawer/CouponEditDrawer";

const CouponMasterTable = () => {
  const { masterCoupons, busy } = useCouponsMaster();

  const [targetCoupon, setTargetCoupon] = useState<ICoupons>();
  // const {
  //     isOpen: isDeleteOpen,
  //     onOpen: onDeleteOpen,
  //     onClose: onDeleteClose,
  // } = useDisclosure();

  const viewProduct = (linkMaster: ICoupons) => {
    setTargetCoupon(linkMaster);
    onViewOpen();
  };

  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setData(
      masterCoupons.map((value: ICoupons, index: number) => ({
        _id: value._id,
        key: index + 1,
        Index: index + 1,
        couponId: value.couponId,
        couponName: value.couponName,
        discountDuration: value.discountDuration,
        discountType: value.discountType,
        discountOff: value.discountOff,
        redemptionsTill: value.redemptionsTill
          ? moment(value.redemptionsTill * 1000).format("MM/DD/YYYY")
          : "Nan",
        value: value,
      }))
    );
    setLoading(false);
  }, [masterCoupons]);

  const columns = [
    {
      title: "SR N0.",
      dataIndex: "Index",
      width: 80,
      maxWidth: 50,
    },
    {
      title: "COUPON ID",
      dataIndex: "couponId",
    },
    {
      title: "NAME",
      dataIndex: "couponName",
    },
    {
      title: "DURATION",
      dataIndex: "discountDuration",
    },
    {
      title: "TYPE",
      dataIndex: "discountType",
    },
    {
      title: "DISCOUNT",
      dataIndex: "discountOff",
    },
    {
      title: "EXP. DATE",
      dataIndex: "redemptionsTill",
    },
    {
      title: "ACTION",
      dataIndex: "value",
      render: (value: any) => (
        <>
          <Button
            onClick={() => {
              viewProduct(value);
            }}
          >
            View
          </Button>
        </>
      ),
    },
  ];
  return (
    <>
      <div
        style={{
          width: "92vw",
          maxWidth: "92%",
          height: "90vh",
          top: "10vh ",
          left: "4vw",
          position: "relative",
        }}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            loading={busy && data.length === 0 ? true : false}
            scroll={{ y: "calc(68vh - 4em)", x: 1400 }}
            rowClassName={() => "gray"}
          />
        )}
      </div>

      {isViewOpen && (
        <CouponEditDrawer
          isOpen={isViewOpen}
          onClose={onViewClose}
          coupon={targetCoupon}
        />
      )}
    </>
  );
};

export default CouponMasterTable;
