import React, { useEffect } from "react";
import { useState } from "react";
import {
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Spacer,
  VStack,
  Switch,
} from "@chakra-ui/react";
import FeedbackTable from "../../componenets/FeedbackTable/FeedbackTable";
import { useFeedbackMessageType } from "../../store/feedbackMessageType/reducer";
import { useDispatch } from "react-redux";
import { REQUEST_FEEDBACK_MESSAGE_TYPE } from "../../store/feedbackMessageType/feedbackMessageTypeActionTypes";
import { IFeedBackType } from "../../types/IFeedBackType";
import { BrowserView, MobileView } from "react-device-detect";

const Feedback = () => {
  const [searchQuery, setSearch] = useState("");
  const [responseType, setResponseType] = useState("");
  const [responseMessageType, setResponseMessageType] = useState<IFeedBackType>(
    { _id: "", title: "" }
  );

  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [isArchived, setIsArchived] = useState<boolean>(false);

  const {
    feedbackMessageType,
    busy,
  }: { feedbackMessageType: IFeedBackType[]; busy: boolean } =
    useFeedbackMessageType();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!feedbackMessageType.length && !busy) {
      dispatch({ type: REQUEST_FEEDBACK_MESSAGE_TYPE });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <MobileView>
        <VStack top={5} position="relative" key={"vStack"}>
          <Heading ml="8" size="lg" fontWeight="semibold" color="black.400">
            Message Center
          </Heading>
          <Spacer></Spacer>
          <Flex style={{margin: "10px 20px"}}>
              <Flex flexDirection={"column"} alignItems="center">
                <FormLabel htmlFor="email-alerts" mb="0">
                  Completed
                </FormLabel>
                <Center>
                  <Switch
                    value={isCompleted.toString()}
                    name={"ss"}
                    id="email-alerts"
                    defaultChecked={isCompleted}
                    onChange={(event: any) => {
                      setIsCompleted(!isCompleted);
                    }}
                  />
                </Center>
              </Flex>
              <Flex flexDirection={"column"} alignItems="center" mx={3}>
                <FormLabel htmlFor="email-alerts" mb="0">
                  Archived
                </FormLabel>
                <Center>
                  <Switch
                    value={isArchived.toString()}
                    name={"ss"}
                    id="email-alerts"
                    defaultChecked={isArchived}
                    onChange={(event: any) => {
                      setIsArchived(!isArchived);
                    }}
                  />
                </Center>
              </Flex>
            <Input
              id="search"
              key={"search"}
              type="text"
              placeholder="Search..."
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            ></Input>
          </Flex>
          <Heading size="md" fontWeight="semibold">
            <Center>
              <Flex>
                <Select
                  placeholder="Select Message Type"
                  key={"feedback-message-type"}
                  marginEnd={2}
                  onChange={(event) => {
                    if (!event.target.value.trim()) {
                      setResponseMessageType({ _id: "", title: "" });
                      return;
                    }
                    setResponseMessageType(
                      JSON.parse(event.target.value) as IFeedBackType
                    );
                  }}
                >
                  {feedbackMessageType.map((fmt) => {
                    return (
                      <option
                        value={JSON.stringify(fmt)}
                        key={fmt._id + "-" + fmt.title}
                      >
                        {fmt.title}
                      </option>
                    );
                  })}
                </Select>
                <Select
                  placeholder="Response type"
                  marginEnd={2}
                  key={"Response type"}
                  onChange={(event) => {
                    setResponseType(event.target.value);
                  }}
                >
                  <option value="TRUE" key={"true"}>
                    TRUE
                  </option>
                  <option value="FALSE" key={"false"}>
                    FALSE
                  </option>
                </Select>
              </Flex>
            </Center>
          </Heading>
        </VStack>
      </MobileView>
      <BrowserView>
        <VStack top={5} position="relative" key={"vStack"}>
          <Flex w="92%">
            <Heading ml="8" size="lg" fontWeight="semibold" color="black.400">
              Message Center
            </Heading>
            <Spacer></Spacer>
            <Heading size="md" fontWeight="semibold">
              <Center>
                <FormControl display="flex" alignItems="center">
                  <Flex flexDirection={"column"}>
                    <FormLabel htmlFor="email-alerts" mb="0">
                      Completed
                    </FormLabel>
                    <Center>
                      <Switch
                        value={isCompleted.toString()}
                        name={"ss"}
                        id="email-alerts"
                        defaultChecked={isCompleted}
                        onChange={(event: any) => {
                          setIsCompleted(!isCompleted);
                        }}
                      />
                    </Center>
                  </Flex>
                </FormControl>

                <FormControl display="flex" alignItems="center">
                  <Flex flexDirection={"column"}>
                    <FormLabel htmlFor="email-alerts" mb="0">
                      Archived
                    </FormLabel>
                    <Center>
                      <Switch
                        value={isArchived.toString()}
                        name={"ss"}
                        id="email-alerts"
                        defaultChecked={isArchived}
                        onChange={(event: any) => {
                          setIsArchived(!isArchived);
                        }}
                      />
                    </Center>
                  </Flex>
                </FormControl>
                <Select
                  placeholder="Select Message Type"
                  key={"feedback-message-type"}
                  marginEnd={2}
                  onChange={(event) => {
                    if (!event.target.value.trim()) {
                      setResponseMessageType({ _id: "", title: "" });
                      return;
                    }
                    setResponseMessageType(
                      JSON.parse(event.target.value) as IFeedBackType
                    );
                  }}
                >
                  {feedbackMessageType.map((fmt) => {
                    return (
                      <option
                        value={JSON.stringify(fmt)}
                        key={fmt._id + "-" + fmt.title}
                      >
                        {fmt.title}
                      </option>
                    );
                  })}
                </Select>
                <Select
                  placeholder="Response type"
                  marginEnd={2}
                  key={"Response type"}
                  onChange={(event) => {
                    setResponseType(event.target.value);
                  }}
                >
                  <option value="TRUE" key={"true"}>
                    TRUE
                  </option>
                  <option value="FALSE" key={"false"}>
                    FALSE
                  </option>
                </Select>
                <Input
                  id="search"
                  key={"search"}
                  type="text"
                  placeholder="Search..."
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></Input>
              </Center>
            </Heading>
          </Flex>
        </VStack>
      </BrowserView>
      <FeedbackTable
        searchQuery={searchQuery}
        responseType={responseType}
        responseMessageType={responseMessageType}
        isCompleted={isCompleted}
        isArchived={isArchived}
      />
    </>
  );
};

export default Feedback;
