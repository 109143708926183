import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { REQUEST_SESSION } from "../../store/auth/authActionType";
import { useAuth } from "../../store/auth/reducer";
import { get } from "lodash";

const AuthenticatedRoute: React.FC = () => {
  const dispatch = useDispatch();
  const { busy, adminUser } = useAuth();
  if (!busy && !get(adminUser, "_id")) {
    dispatch({ type: REQUEST_SESSION });
  }
  if (busy) {
    return <div>loading....</div>;
  }

  if (adminUser.userType === "ADMIN" || adminUser.userType === "SUPER ADMIN") {
    return <Outlet />;
  }
  if (get(adminUser, "_id")) return <Navigate to={{ pathname: "/login" }} />;

  return <div></div>;
};

export default AuthenticatedRoute;
