import { Flex, Text, Icon, Menu, MenuButton, Box } from "@chakra-ui/react";
import React from "react";

interface aa {
  icon: any;
  title: any;
}

const NavItem: React.FC<aa> = ({ icon, title }) => {
  return (
    <Flex mt={1} flexDir="column" w="100%" alignItems="flex-start">
      <Menu placement="right">
        <Box
          p={3}
          borderRadius={8}
          _hover={{ textDecor: "none", backgroundColor: "#AEC8CA" }}
          w="100%"
        >
          <MenuButton w="100%">
            <Flex>
              <Icon as={icon} fontSize="xl" color="gray.500" />
              <Text ml={5} display="flex">
                {title}
              </Text>
            </Flex>
          </MenuButton>
        </Box>
      </Menu>
    </Flex>
  );
};
export default NavItem;
