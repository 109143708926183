import axios from "axios";
import { get } from "lodash";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  ERROR_SEND_ADMIN_FEEDBACK,
  REQUEST_FEEDBACK_LIST,
  REQUEST_SEND_ADMIN_FEEDBACK,
  SET_FEEDBACK_LIST,
  SUCCESS_REPLY_SEND,
  REQUEST_UPDATE_FEEDBACK_DETAIL,
  ERROR_UPDATE_FEEDBACK_DETAIL,
  SUCCESS_UPDATE_FEEDBACK_DETAIL,
} from "./feedbackActionType";

function* requestFeedbacks(action: Record<string, any>): any {
  try {
    const result: any = yield call(getFeedbacks);
    yield put({ type: SET_FEEDBACK_LIST, payload: result.data });
  } catch (err) {
    console.error(`Error fetching feedback`, action);
  }
}

function* requestReplyFeedbackAdmin(action: Record<string, any>): any {
  try {
    yield call(replyFeedbackAdmin, action.payload);
    yield put({
      type: SUCCESS_REPLY_SEND,
    });
  } catch (error: any) {
    console.log(error);
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = error.response.data.message || "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_SEND_ADMIN_FEEDBACK, payload: message });
  }
}

function* requestUpdateMasterFeedback(action: Record<string, any>): any {
  try {
    const result = yield call(
      updateMasterFeedback,
      action.data.payload,
      action.data._id
    );
    yield put({
      type: SUCCESS_UPDATE_FEEDBACK_DETAIL,
      payload: result.data,
    });
    action && action.onSuccess && action.onSuccess();

    // update relevant category as well
  } catch (error: any) {
    console.log(error);
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = error.response.data.message || "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_UPDATE_FEEDBACK_DETAIL, payload: message });
  }
}

export function getFeedbacks() {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/feedback`,
    withCredentials: true,
  });
}

export function replyFeedbackAdmin(payload: Record<string, any>) {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL}/feedback/admin_reply/`,
    data: payload,
    withCredentials: true,
  });
}

export function updateMasterFeedback(
  payload: Record<string, any>,
  _id: string
) {
  return axios({
    method: "patch",
    url: `${process.env.REACT_APP_BASE_URL}/feedback/${_id}`,
    data: payload,
    withCredentials: true,
  });
}

const feedbackSaga = function* () {
  yield all([
    takeLatest(REQUEST_FEEDBACK_LIST, requestFeedbacks),
    takeLatest(REQUEST_SEND_ADMIN_FEEDBACK, requestReplyFeedbackAdmin),
    takeLatest(REQUEST_UPDATE_FEEDBACK_DETAIL, requestUpdateMasterFeedback),
  ]);
};
export default feedbackSaga;
