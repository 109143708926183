import { useSelector } from "react-redux";
import { get } from "lodash";

import {
    REQUEST_MASTER_MOBILE_COUPON,
    REQUEST_CREATE_MASTER_MOBILE_COUPON,
    REQUEST_UPDATE_MASTER_MOBILE_COUPON,
    SET_MASTER_MOBILE_COUPON,
    SUCCESS_CREATE_MASTER_MOBILE_COUPON,
    SUCCESS_UPDATE_MASTER_MOBILE_COUPON,
    ERROR_GET_MASTER_MOBILE_COUPON,
    ERROR_CREATE_MASTER_MOBILE_COUPON,
    ERROR_UPDATE_MASTER_MOBILE_COUPON,
    SET_MESSAGE_MASTER_MOBILE_COUPON,
} from "./mobilecouponActionTypes"
import { IMobileCoupons } from "../../types/IMobileCoupons";

interface IState {
    busy: boolean;
    message: string;
    masterMobileCoupons: IMobileCoupons[];
}

const initialState: IState =
{
    busy: false,
    message: "",
    masterMobileCoupons: [],
};

const mobilecoupons = (state = initialState, action: Record<string, any>) => {
    switch (action.type) {
        case REQUEST_MASTER_MOBILE_COUPON:
        case REQUEST_CREATE_MASTER_MOBILE_COUPON:
        case REQUEST_UPDATE_MASTER_MOBILE_COUPON:
            return {
                ...state,
                busy: true,
                message: "",
            };

        case SET_MESSAGE_MASTER_MOBILE_COUPON:
            return {
                ...state,
                message: "",
            };

        case SET_MASTER_MOBILE_COUPON:
            return {
                ...state,
                busy: false,
                message: "",
                masterMobileCoupons: action.payload,
            };

        case SUCCESS_CREATE_MASTER_MOBILE_COUPON:
            return {
                ...state,
                busy: false,
                message: "",
                masterMobileCoupons: [...state.masterMobileCoupons, action.payload],
            };

        case SUCCESS_UPDATE_MASTER_MOBILE_COUPON:
            return {
                ...state,
                busy: false,
                message: "",
                masterMobileCoupons: state.masterMobileCoupons.map((mp) => {
                    return get(mp, "_id") === action.payload._id ? action.payload : mp;
                }),
            };

        case ERROR_GET_MASTER_MOBILE_COUPON:
        case ERROR_CREATE_MASTER_MOBILE_COUPON:
        case ERROR_UPDATE_MASTER_MOBILE_COUPON:
            return {
                ...state,
                busy: false,
                message:
                    action.payload || "Something happened wrong try again after sometime",
            };
        default:
            return state;
    }
}

export default mobilecoupons;


export function useMobileCouponsMaster() {
    return useSelector((state: Record<string, any>) => state.mobilecoupons);
}