import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { Table } from "antd";
import axios from "axios";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { IoMdTrash } from "react-icons/io";
import { useDispatch } from "react-redux";
import {
  DELETE_USER_REQUEST,
  REQUEST_ACCOUNTS,
} from "../../store/account/accountActionType";
import { useAccounts } from "../../store/account/reducer";
import { IAccount } from "../../types/account";
interface Props {
  account: IAccount;
  isOpen: any;
  onClose: any;
}
const AccountTagView: React.FC<Props> = ({ isOpen, onClose, account }) => {
  const dispatch = useDispatch();
  //   const { busy, message, accounts } = useAccounts();
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  // const { masterEvent: events } = useEventMaster();
  const { _id } = account;
  const toast = useToast();

  useEffect(() => {
    // console.log(ticketId);
    // console.log(events);
    setLoader(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/tags/byAccId/${_id}`)
      .then(function (response) {
        setData(
          response.data.map((value: any, i: number) => ({
            _id: value._id,
            key: i + 1,
            tag: `${process.env.REACT_APP_FRONTEND_URL}/${value.urlName}`,
            value: value,
          }))
        );
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoader(false);
        toast({
          title: "Error.",
          description: "Tag is not show.",
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      });
  }, [_id]);

  const columns = [
    {
      title: "SR No.",
      dataIndex: "key",
      width: 80,
    },
    {
      title: "TAGS",
      dataIndex: "tag",
    },
    {
      title: "ACTION",
      dataIndex: "value",
      // width: 340,
      render: (value: any) => (
        <>
          <IconButton
            aria-label="delete tag"
            key={"deleteLinkButton"}
            marginLeft={2}
            onClick={() => {
              deleteFunction(value);
            }}
            icon={<IoMdTrash />}
          ></IconButton>
        </>
      ),
    },
  ];

  const deleteFunction = (id: any) => {
    let payload = [id._id];
    axios({
      // url: "/backend/auth/send_phone_otp",
      url: `${process.env.REACT_APP_BASE_URL}/tags`,
      method: "delete",
      data: {
        id: payload,
      },
    })
      .then((dat) => {
        let temp: any = data.filter(
          (user) => !payload.includes(get(user, "_id"))
        );
        console.log(temp);
        setData(
          temp.map((value: any, i: number) => ({
            _id: value._id,
            key: i + 1,
            tag: `${value.tag}`,
            value: value,
          }))
        );
        dispatch({ type: REQUEST_ACCOUNTS });
        toast({
          title: "Success.",
          description: "Successfully delete tag. ",
          status: "success",
          isClosable: true,
          position: "top-right",
        });
      })
      .catch((error) => {
        console.log(error, "23233333333332");
        toast({
          title: "error",
          description: error.response.data.message,
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Account Tag</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Table
            rowKey={(record) => record._id}
            columns={columns}
            dataSource={data}
            loading={loader && data.length === 0 ? true : false}
            // scroll={{ y: "calc(70vh - 4em)", x: 1400 }}
            rowClassName={(record: any) => record.color}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default AccountTagView;
