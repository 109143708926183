import { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  CircularProgress,
  Center,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import Joi from "joi";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SET_USER } from "../../store/auth/authActionType";
import validator from "validator";

const loginSchema = Joi.object({
  userName: Joi.string().custom((v) => {
    if (validator.isEmail(v)) {
      return v;
    }
    const phoneRegex = /^\+([0-9]{1,3})\)?[\s]?[0-9]{6,14}$/;
    if (phoneRegex.test(v)) {
      return v;
    }
    throw new Error("Please Provide valid data");
  }),
  password: Joi.string().required(),
  // email: Joi.string().required(),
  // otp: Joi.string().required(),
});
function Login() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const login = () => {
    const data = {
      userName,
      password,
    };

    if (loginSchema.validate(data).error) {
      if (
        loginSchema.validate(data).error?.message ===
        `"userName" is not allowed to be empty`
      ) {
        setErrorMsg("Please enter Email / Phone");
      } else if (
        loginSchema.validate(data).error?.message ===
        `"password" is not allowed to be empty`
      ) {
        setErrorMsg("Please enter Password");
      } else if (
        loginSchema.validate(data).error?.message ===
        `"userName" failed custom validation because Please Provide valid data`
      ) {
        setErrorMsg("Please enter valid Email / Phone");
      }
      setIsSubmitting(false);
      return;
    } else {
      const payloadValue = loginSchema.validate(data).value;
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/auth/login`,
        data: payloadValue,
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Credentials": "true",
        },
      })
        .then((res) => {
          localStorage.setItem(
            "token",
            res.headers["X-Auth-Token"] || res.headers["x-auth-token"]
          );
          setIsSubmitting(false);

          if (
            res.data.userType === "ADMIN" ||
            res.data.userType === "SUPER ADMIN"
          ) {
            dispatch({ type: SET_USER, payload: res.data });
            navigate("../");
          } else {
            alert("only admin can login");
          }
        })
        .catch((error) => {
          setIsSubmitting(false);
          if (error === "Error: Network Error") {
            setErrorMsg(
              "Something went wrong, please try again after some time or Refresh the Page."
            );
          } else if (error.response.status === 401) {
            setErrorMsg(error.response.data.message);
          } else if (error.response.status === 500) {
            setErrorMsg("Something happened wrong try again after sometime.");
          } else if (error.response.status === 422) {
            setErrorMsg(error.response.data.message);
          } else if (error.response.status === 415) {
            setErrorMsg(error.response.data.message);
          }
        });
    }
  };
  const [emailVeify, setEmailVerify] = useState(true)
  const [otpVerify, setOtpVerify] = useState(false)
  const [loginPopup, setLoginPopup] = useState(false)

  const toast = useToast();
  const getOtp = async () => {
    axios({
      // url: "/backend/auth/send_phone_otp",
      url: `${process.env.REACT_APP_BASE_URL}/user/send_email_otp_admin`,
      method: "POST",
      data: {
        email: email,
      },
    })
      .then((data) => {
        console.log(data.data.message, "&&&&&&&&&&&&&&&&&&&&&&&&")
        setOtpVerify(true)
        setEmailVerify(false)
        toast({
          title: "Success.",
          description: data.data.message,
          status: "success",
          isClosable: true,
          position: "top-right",
        });
      })
      .catch((error) => {
        console.log(error, "23233333333332")
        toast({
          title: "error",
          description: error.response.data.message,
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      });
  };
  // console.log(email, "111111111111")

  const verifyOtp = async () => {
    axios({
      // url: "/backend/auth/send_phone_otp",
      url: `${process.env.REACT_APP_BASE_URL}/user/verify_otp`,
      method: "POST",
      data: {
        email: email,
        otp: otp,
      },
    })
      .then(async (data) => {
        console.log(data)
        setOtpVerify(false)
        setLoginPopup(true)
      })
      .catch((err) => {
        console.log(err)
      });
  };


  return (
    <Flex minHeight="100vh" width="full" align="center" justifyContent="center">
      <Box
        borderWidth={1}
        px={4}
        width="full"
        maxWidth="500px"
        borderRadius={4}
        textAlign="center"
        boxShadow="lg"

      >
        <Box p={4} >
          <Box textAlign="center">
            <Text>
              <br></br>
            </Text>
            {emailVeify || otpVerify ? (
              <Heading>Two-step Verification</Heading>
            ) : (
              <Heading>Sign In to Your Account</Heading>
            )}
            <Text>
              <br></br>
            </Text>
            <Text color="red">{errorMsg}</Text>
          </Box>
          {/* <Box my={8} textAlign="left"> */}
          {emailVeify ? (
            <>
              <Box my={2} textAlign="left">
                <FormControl isRequired>
                  <FormLabel>Enter Email</FormLabel>
                  <Input
                    type="userName"
                    placeholder="Enter your Email"

                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
                {isSubmitting ? (
                  <Flex
                    bg="white.500"
                    color="gery.500"
                    width="full"
                    justifyContent="center"
                    mt={4}
                    mb={4}
                  >
                    <Center>
                      <CircularProgress
                        size={7}
                        isIndeterminate
                        color="green.500"
                        m={1}

                      />
                    </Center>
                  </Flex>
                ) : (
                  <Button
                    bg="green.500"
                    color="white"
                    width="full"
                    mt={4}
                    mb={4}
                    onClick={() => {
                      // setIsSubmitting(true);
                      // login(); 

                      getOtp()
                    }}
                  >
                    Next
                  </Button>
                )}
              </Box>
            </>
          ) : null}

          {otpVerify ? (
            <>
              <Box my={2} textAlign="left">
                <FormControl isRequired>
                  <FormLabel>Enter the verification code </FormLabel>
                  <Input
                    type="userName"
                    placeholder="Enter your otp"

                    onChange={(e) => setOtp(e.target.value)}
                  />
                </FormControl>

                {isSubmitting ? (
                  <Flex
                    bg="white.500"
                    color="gery.500"
                    width="full"
                    justifyContent="center"
                    mt={4}
                    mb={4}
                  >
                    <Center>
                      <CircularProgress
                        size={7}
                        isIndeterminate
                        color="green.500"
                        m={1}

                      />
                    </Center>
                  </Flex>
                ) : (
                  <Button
                    bg="green.500"
                    color="white"
                    width="full"
                    mt={4}
                    mb={4}
                    onClick={() => {
                      // setIsSubmitting(true);
                      verifyOtp();

                    }}
                  >
                    Next
                  </Button>
                )}
              </Box>
            </>
          ) : null}
        </Box>

        {loginPopup ? (
          <Box my={2} textAlign="left">
            <FormControl isRequired>
              <FormLabel>Enter Email / Phone </FormLabel>
              <Input
                type="userName"
                placeholder="Enter your Email / Phone"
                onChange={(e) => setUserName(e.target.value)}
              />
            </FormControl>

            <FormControl mt={4} isRequired>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                placeholder="Enter your password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>

            {isSubmitting ? (
              <Flex
                bg="white.500"
                color="gery.500"
                width="full"
                justifyContent="center"
                mt={4} mb={8}
              >
                <Center>
                  <CircularProgress
                    size={7}
                    isIndeterminate
                    color="green.500"
                    m={1}
                  />
                </Center>
              </Flex>
            ) : (
              <Button
                bg="green.500"
                color="white"
                width="full"
                mt={4}
                onClick={() => {
                  setIsSubmitting(true);
                  login();
                }}
                mb={8}
              >
                Sign In
              </Button>
            )}
          </Box>
        ) : null}

      </Box>
    </Flex>
  );
}

export default Login;
