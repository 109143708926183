import {
  Alert,
  AlertIcon,
  Button,
  CloseButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  AlertTitle,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { REQUEST_DEACTIVE_MASTER_LINK } from "../../store/linkMaster/linkActionTypes";
import { useLinksMaster } from "../../store/linkMaster/reducer";
interface Props {
  link: Record<string, any>;
  isOpen: any;
  onClose: any;
}
const LinkDeactiveConfirmation: React.FC<Props> = ({
  link,
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { masterLinks: links, busy, message } = useLinksMaster();
  const { _id, isDeactive } = link;

  useEffect(() => {
    const findLink = links.find((val: Record<string, any>) => val._id === _id);
    if (findLink.isDeactive !== isDeactive) {
      onClose();
    }
  }, [_id, links, onClose, isDeactive]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Link</ModalHeader>
        <ModalCloseButton disabled={busy} />
        <ModalBody>
          <Text fontWeight="bold" mb="1rem">
            {link.isDeactive
              ? "You are going to show this Link? "
              : "You are going to hide this Link? "}
            {link.title}
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose} disabled={busy}>
            Close
          </Button>
          <Button
            colorScheme="red"
            variant={"outline"}
            mr={3}
            disabled={busy}
            onClick={() => {
              dispatch({
                type: REQUEST_DEACTIVE_MASTER_LINK,
                payload: { _id: link._id, isDeactive: !link.isDeactive },
              });
            }}
          >
            Submit
          </Button>
        </ModalFooter>
        {message && (
          <ModalFooter>
            <Alert status="error">
              <AlertIcon />
              <AlertTitle mr={2}>{message}</AlertTitle>
              <CloseButton position="absolute" right="8px" top="8px" />
            </Alert>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
export default LinkDeactiveConfirmation;
