export const REQUEST_USERS = "REQUEST_USERS";
export const SET_USERS = "SET_USERS";

export const REQUEST_DELETE_USER = "REQUEST_DELETE_USER";
export const SUCCESS_DELETE_USER = "SUCCESS_DELETE_USER";
export const ERROR_DELETE_USER = "ERROR_DELETE_USER";

export const REQUEST_ASSIGN_ROLE_USER = "REQUEST_ASSIGN_ROLE_USER";
export const SUCCESS_ASSIGN_ROLE_USER = "SUCCESS_ASSIGN_ROLE_USER";
export const ERROR_ASSIGN_ROLE_USER = "ERROR_ASSIGN_ROLE_USER";

export const REQUEST_RESETPOPUP = "REQUEST_RESETPOPUP";
export const SUCCESS_RESETPOPUP = "SUCCESS_RESETPOPUP";
export const ERROR_RESETPOPUP = "ERROR_RESETPOPUP";

export const PRO_USER_REQUEST = "PRO_USER_REQUEST";
export const PRO_USER_SUCCESS = "PRO_USER_SUCCESS";
export const PRO_USER_ERROR = "PRO_USER_ERROR";
