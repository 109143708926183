export const REQUEST_MASTER_FAQ = "REQUEST_MASTER_FAQ";
export const SET_MASTER_FAQ = "SET_MASTER_FAQ";
export const ERROR_GET_MASTER_FAQ = "ERROR_GET_MASTER_FAQ";

export const REQUEST_CREATE_MASTER_FAQ = "REQUEST_CREATE_MASTER_FAQ";
export const SUCCESS_CREATE_MASTER_FAQ = "SUCCESS_CREATE_MASTER_FAQ";
export const ERROR_CREATE_MASTER_FAQ = "ERROR_CREATE_MASTER_FAQ";

export const REQUEST_UPDATE_MASTER_FAQ = "REQUEST_UPDATE_MASTER_FAQ";
export const SUCCESS_UPDATE_MASTER_FAQ = "SUCCESS_UPDATE_MASTER_FAQ";
export const ERROR_UPDATE_MASTER_FAQ = "ERROR_UPDATE_MASTER_FAQ";

export const REQUEST_DELETE_MASTER_FAQ = "REQUEST_DELETE_MASTER_FAQ";
export const SUCCESS_DELETE_MASTER_FAQ = "SUCCESS_DELETE_MASTER_FAQ";
export const ERROR_DELETE_MASTER_FAQ = "ERROR_DELETE_MASTER_FAQ";

export const SET_MESSAGE_MASTER_FAQ = "SET_MESSAGE_MASTER_FAQ";
