import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Flex,
  IconButton,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { Spin, Table } from "antd";
import { get } from "lodash";
import { IoMdLogIn, IoMdTrash } from "react-icons/io";
import { useUsers } from "../../store/user/reducer";
import { useDispatch } from "react-redux";
import { REQUEST_USERS } from "../../store/user/userActionType";
import { IUser } from "../../types/user";
import UserDeleteConfirmation from "../modals/UserDeleteConfirmation";
import { useAuth } from "../../store/auth/reducer";
import UserAssignRoleConfirmation from "../modals/UserAssignRoleConfirmation";
import { RiUserStarFill } from "react-icons/ri";
import { FaUser } from "react-icons/fa";
import UserProConfirmation from "../modals/UserProConfirmation";
import moment from "moment";
import { getAuthToken } from "../../script/getAuthToken";
import UserVisitPopUp from "../modals/UserVisitPopUp";
import { FcGoogle } from "react-icons/fc";
import axios from "axios";

export interface User {
  proUser: any;
  searchQuery: string;
  setCSVData: any;
  startDate: any;
  subData: any;
}

const UserTable: React.FC<User> = ({
  searchQuery: query,
  setCSVData,
  proUser,
  startDate,
  subData,
}) => {
  const { users, busy } = useUsers();
  const [deleteData, setDeleteData] = useState<IUser>();
  const { adminUser } = useAuth();
  const searchColumns = useMemo(
    () => ["firstName", "lastName", "email", "phoneNumber", "gender"],
    []
  );
  const [isAssignRole, setIsAssignRole] = useState<boolean>(false);
  const [assignRoleData, setAssignRoleData] = useState<IUser>();
  const [visit, setVisit] = useState<IUser>();
  const [page, setPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(1);

  const {
    isOpen: isPopUpOpen,
    onOpen: onPopUpOpen,
    onClose: onPopUpClose,
  } = useDisclosure();
  const popup = (value: IUser) => {
    setVisit(value);
    onPopUpOpen();
  };

  const {
    isOpen: isAssignRoleOpen,
    onOpen: onAssignRoleOpen,
    onClose: onAssignRoleClose,
  } = useDisclosure();

  const openAssignRoleModal = (value: IUser) => {
    setAssignRoleData(value);
    setIsAssignRole(true);
    onAssignRoleOpen();
  };

  const search = (user: IUser) => {
    const tempQuery = query.split(" ");

    return tempQuery.every((item) => {
      return (
        searchColumns.some((column) => {
          return (
            get(user, column, "")
              .toString()
              .toLowerCase()
              .indexOf(item.toLowerCase()) > -1
          );
        }) &&
        (!proUser.length ||
          (proUser === "TRUE" && user.isPro) ||
          (proUser === "FALSE" && !user.isPro))
      );
    });
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: REQUEST_USERS });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCSVData(
      users.map((value: any) => {
        return {
          EMAIL: value.email,
          FIRSTNAME: value.firstName ? value.firstName : "Nan",
          LASTNAME: value.lastName ? value.lastName : "Nan",
          PHONENUMBER: value.phoneNumber ? value.phoneNumber : "",
          DOB: value.DOB ? moment(value.DOB).format("MM/DD/YYYY") : "",
          CREATION_DATE: value.createdAt
            ? moment(value.createdAt).format("MM/DD/YYYY")
            : moment("2021-07-25T09:52:07.574Z").format("MM/DD/YYYY"),
          GENDER: value.gender ? value.gender : "",
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const deleteFunction = (dataDelete: IUser) => {
    setDeleteData(dataDelete);
    onDeleteOpen();
  };

  const {
    isOpen: isProOpen,
    onOpen: onProOpen,
    onClose: onProClose,
  } = useDisclosure();

  const [proData, setProData] = useState<IUser>();
  const [isPro, setIsPro] = useState<boolean>(false);

  const openProDataModal = (value: IUser) => {
    setProData(value);
    setIsPro(true);
    onProOpen();
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (
      startDate[0] === "" ||
      (startDate[0] === "Invalid date" && startDate[1] === "") ||
      startDate[1] === "Invalid date"
    ) {
      if (subData) {
        // console.log(subData);
        setData(
          subData.filter(search).map((value: IUser, index: number) => ({
            _id: value._id,
            key: index + 1,
            Index: index + 1,
            email: value.email,
            firstName: value.firstName ? value.firstName : "Nan",
            lastName: value.lastName ? value.lastName : "Nan",
            phoneNumber: value.phoneNumber ? value.phoneNumber : "",
            gender: value.gender ? value.gender : "",
            subscriptionTill: value.subscriptionTill
              ? moment(value.subscriptionTill).format("MM/DD/YYYY")
              : "N/A",
            DOB: value.DOB ? moment(value.DOB).format("MM/DD/YYYY") : "Nan",
            createdAt: value.createdAt
              ? moment(value.createdAt).format("MM/DD/YYYY")
              : moment("2022-02-25T09:52:07.574Z").format("MM/DD/YYYY"),
            value: value,
          }))
        );
      } else {
        setData(
          users.filter(search).map((value: IUser, index: number) => ({
            _id: value._id,
            key: index + 1,
            Index: index + 1,
            email: value.email,
            firstName: value.firstName ? value.firstName : "Nan",
            lastName: value.lastName ? value.lastName : "Nan",
            phoneNumber: value.phoneNumber ? value.phoneNumber : "",
            gender: value.gender ? value.gender : "",
            subscriptionTill: value.subscriptionTill
              ? moment(value.subscriptionTill).format("MM/DD/YYYY")
              : "N/A",
            DOB: value.DOB ? moment(value.DOB).format("MM/DD/YYYY") : "Nan",
            createdAt: value.createdAt
              ? moment(value.createdAt).format("MM/DD/YYYY")
              : moment("2022-02-25T09:52:07.574Z").format("MM/DD/YYYY"),
            value: value,
          }))
        );
      }
    } else {
      setData(
        users
          .filter((item: any) => {
            // console.log(moment(item.createdAt).format("MM/DD/YYYY") > startDate[1])
            return (
              moment(item.createdAt) > moment(startDate[0]) &&
              moment(item.createdAt) < moment(startDate[1])
            );
          })
          .filter(search)
          .map((value: IUser, index: number) => ({
            _id: value._id,
            key: index + 1,
            Index: index + 1,
            email: value.email,
            firstName: value.firstName ? value.firstName : "",
            lastName: value.lastName ? value.lastName : "",
            phoneNumber: value.phoneNumber ? value.phoneNumber : "",
            gender: value.gender ? value.gender : "",
            subscriptionTill: value.subscriptionTill
              ? moment(value.subscriptionTill).format("MM/DD/YYYY")
              : "N/A",
            DOB: value.DOB ? moment(value.DOB).format("MM/DD/YYYY") : "Nan",
            createdAt: value.createdAt
              ? moment(value.createdAt).format("MM/DD/YYYY")
              : moment("2022-02-25T09:52:07.574Z").format("MM/DD/YYYY"),
            value: value,
          }))
      );
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, proUser, startDate, query, subData]);

  const columns = [
    {
      title: "SR N0.",
      width: 75,
      render: (text: string, record: any, index: number) =>
        (page - 1) * paginationSize + index + 1,
    },
    {
      title: "ACTION",
      dataIndex: "value",
      width: 340,
      render: (value: any) => (
        <>
          {get(value, "isPro") ? (
            <IconButton
              style={{
                cursor: "unset",
                color: "white",
                backgroundColor: "#419182",
              }}
              aria-label="Pro User"
              icon={<RiUserStarFill />}
              onClick={() => {
                openProDataModal(value);
              }}
              // marginLeft={2}
            />
          ) : (
            <IconButton
              style={{ cursor: "unset" }}
              aria-label="Pro User"
              icon={<FaUser />}
              // marginLeft={2}
              onClick={() => {
                openProDataModal(value);
              }}
            />
          )}

          {adminUser.userType === "SUPER ADMIN" ? (
            <Button
              ml={2}
              onClick={() => {
                openAssignRoleModal(value);
              }}
            >
              {get(value, "userType")}
            </Button>
          ) : null}
          <IconButton
            aria-label="View Blog"
            ml={2}
            icon={<IoMdLogIn color="#3e8578" />}
            marginRight={2}
            onClick={() => {
              getAuthToken(get(value, "_id"));
            }}
          />
          <Button
            // ml={3}
            onClick={() => {
              popup(value);
            }}
          >
            Pop up
          </Button>
          <IconButton
            aria-label="delete link"
            key={"deleteLinkButton"}
            marginLeft={2}
            onClick={() => {
              deleteFunction(value);
            }}
            icon={<IoMdTrash />}
          ></IconButton>
        </>
      ),
    },
    {
      title: "FIRSTNAME",
      dataIndex: "firstName",
      width: 120,
      sorter: (a: any, b: any) => a.firstName.localeCompare(b.firstName),
    },
    {
      title: "LASTNAME",
      dataIndex: "lastName",
      width: 120,
      sorter: (a: any, b: any) => a.lastName.localeCompare(b.lastName),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      width: 250,
      render: (email: any, value: any) => (
        <>
          <Flex alignItems="center">
            <Text>{email}</Text>
            {value.value.googleLogin ? (
              <FcGoogle style={{ marginLeft: "10px" }} />
            ) : (
              ""
            )}
          </Flex>
        </>
      ),
    },
    {
      title: "PHONE",
      dataIndex: "phoneNumber",
      width: 150,
    },
    {
      title: "GENDER",
      dataIndex: "gender",
      width: 100,
    },
    {
      title: "DOB",
      dataIndex: "DOB",
      width: 120,
      sorter: (a: any, b: any) =>
        moment(a.DOB, "MM/DD/YYYY").unix() - moment(b.DOB, "MM/DD/YYYY").unix(),
    },
    {
      title: "CREATION DATE",
      dataIndex: "createdAt",
      width: 120,
      sorter: (a: any, b: any) =>
        moment(a.createdAt, "MM/DD/YYYY").unix() -
        moment(b.createdAt, "MM/DD/YYYY").unix(),
    },
    {
      title: "EXPIRY DATE",
      dataIndex: "subscriptionTill",
      width: 120,
      sorter: (a: any, b: any) =>
        moment(a.subscriptionTill, "MM/DD/YYYY").unix() -
        moment(b.subscriptionTill, "MM/DD/YYYY").unix(),
    },
  ];

  return (
    <>
      <div
        style={{
          width: "96vw",
          maxWidth: "96vw",
          height: "90vh",
          top: "6vh ",
          left: "2vw",
          position: "relative",
        }}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            loading={busy && users.length === 0 ? true : false}
            scroll={{ y: "calc(68vh - 2em)", x: 1400 }}
            rowClassName={() => "gray"}
            pagination={{
              onChange(current, pageSize) {
                setPage(current);
                setPaginationSize(pageSize);
              },
              hideOnSinglePage: true,
              showSizeChanger: true,
            }}
          />
        )}
      </div>
      {isDeleteOpen && deleteData && (
        <UserDeleteConfirmation
          user={deleteData}
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
        />
      )}
      {isAssignRole && assignRoleData && (
        <UserAssignRoleConfirmation
          isOpen={isAssignRoleOpen}
          onClose={onAssignRoleClose}
          user={assignRoleData}
        />
      )}

      {isPro && proData && (
        <UserProConfirmation
          isOpen={isProOpen}
          onClose={onProClose}
          user={proData}
        />
      )}

      {isPopUpOpen && visit && (
        <UserVisitPopUp
          isOpen={isPopUpOpen}
          onClose={onPopUpClose}
          user={visit}
        />
      )}
    </>
  );
};

export default UserTable;
