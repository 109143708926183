import { get } from "lodash";
import { useSelector } from "react-redux";
import { ITag } from "../../types/tags";

import {
  REQUEST_MASTER_TAG,
  REQUEST_CREATE_MASTER_TAG,
  REQUEST_SORTED_TAG,
  SET_MASTER_TAG,
  SUCCESS_CREATE_MASTER_TAG,
  SET_SORTED_TAG,
  ERROR_GET_MASTER_TAG,
  ERROR_CREATE_MASTER_TAG,
  ERROR_GET_SORTED_TAG,
  SET_MESSAGE_MASTER_TAG,
  REQUEST_DELETE_MASTER_TAG,
  SUCCESS_DELETE_MASTER_TAG,
  ERROR_DELETE_MASTER_TAG,
} from "./tagActionTypes";

interface IState {
  busy: boolean;
  message: string;
  masterTags: ITag[];
  tagsCount: number;
}

const initialState: IState = {
  busy: false,
  message: "",
  masterTags: [],
  tagsCount: 0,
};

const tags = (state = initialState, action: Record<string, any>) => {
  switch (action.type) {
    case REQUEST_MASTER_TAG:
    case REQUEST_CREATE_MASTER_TAG:
    case REQUEST_DELETE_MASTER_TAG:
    case REQUEST_SORTED_TAG:
      return {
        ...state,
        busy: true,
        message: "",
      };

    case SET_MESSAGE_MASTER_TAG:
      return {
        ...state,
        message: "",
      };

    case SET_MASTER_TAG:
    case SET_SORTED_TAG:
      return {
        ...state,
        busy: false,
        message: "",
        masterTags: action.payload.tags,
        tagsCount: action.payload.tagsCount,
      };

    case SUCCESS_DELETE_MASTER_TAG:
      return {
        ...state,
        busy: false,
        message: "",
        masterTags: state.masterTags.filter(
          (user) => !action.payload.includes(get(user, "_id"))
        ),
      };

    case SUCCESS_CREATE_MASTER_TAG:
      return {
        ...state,
        busy: false,
        message: "",
        masterTags: [...state.masterTags, action.payload],
        tagsCount: action.payload.tagCount,
      };

    case ERROR_GET_MASTER_TAG:
    case ERROR_CREATE_MASTER_TAG:
    case ERROR_DELETE_MASTER_TAG:
    case ERROR_GET_SORTED_TAG:
      return {
        ...state,
        busy: false,
        message:
          action.payload || "Something happened wrong try again after sometime",
      };
    default:
      return state;
  }
};

export default tags;

export function useTagsMaster() {
  return useSelector((state: Record<string, any>) => state.tags);
}
