import {
  Center,
  Flex,
  Heading,
  HStack,
  Spacer,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import CouponMasterTable from "../../componenets/CouponMasterTable/CouponMasterTable";
import CouponEditDrawer from "../../componenets/drawer/CouponEditDrawer";
import { REQUEST_MASTER_COUPON } from "../../store/coupon/couponActionType";
import { useCouponsMaster } from "../../store/coupon/reducer";
import { BrowserView, MobileView } from "react-device-detect";

const Coupons = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const { masterCoupons, busy } = useCouponsMaster();

  useEffect(() => {
    if (!busy && !(masterCoupons || []).length) {
      dispatch({ type: REQUEST_MASTER_COUPON });
    }
  }, []);
  return (
    <>
      <MobileView>
        <VStack top={5} position="relative">
          <Flex w="92%">
            <Heading
              ml="60px"
              mt="6px"
              size="lg"
              fontWeight="semibold"
              color="black.400"
            >
              Coupons
            </Heading>

            <Spacer></Spacer>
            <Heading size="md" fontWeight="semibold">
              <HStack>
                <Center
                  bg="tomato"
                  h="100%"
                  color="white"
                  p={3}
                  onClick={() => {
                    onOpen();
                    //   setaddErrorMsg("");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  + Add Coupon
                </Center>
              </HStack>
            </Heading>
          </Flex>
        </VStack>
      </MobileView>
      <BrowserView>
        <VStack top={5} position="relative">
          <Flex w="92%">
            <Heading
              ml="20px"
              size="lg"
              fontWeight="semibold"
              color="black.400"
            >
              Coupons
            </Heading>

            <Spacer></Spacer>
            <Heading size="md" fontWeight="semibold">
              <HStack>
                <Center
                  bg="tomato"
                  h="100%"
                  color="white"
                  p={3}
                  onClick={() => {
                    onOpen();
                    //   setaddErrorMsg("");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  + Add Coupon
                </Center>
              </HStack>
            </Heading>
          </Flex>
        </VStack>
      </BrowserView>
      <CouponMasterTable />
      {isOpen && <CouponEditDrawer isOpen onClose={onClose} />}
    </>
  );
};

export default Coupons;
