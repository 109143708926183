import axios from "axios";
import { get } from "lodash";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  ERROR_CREATE_MASTER_LINK,
  ERROR_DEACTIVE_MASTER_LINK,
  ERROR_DELETE_MASTER_LINK,
  ERROR_UPDATE_MASTER_LINK,
  REQUEST_CREATE_MASTER_LINK,
  REQUEST_DEACTIVE_MASTER_LINK,
  REQUEST_DELETE_MASTER_LINK,
  REQUEST_MASTER_LINKS,
  REQUEST_UPDATE_MASTER_LINK,
  SET_MASTER_LINKS,
  SUCCESS_CREATE_MASTER_LINK,
  SUCCESS_DEACTIVE_MASTER_LINK,
  SUCCESS_DELETE_MASTER_LINK,
  SUCCESS_UPDATE_MASTER_LINK,
} from "./linkActionTypes";

function* requestMasterLinks(action: Record<string, any>): any {
  try {
    const result: any = yield call(getLinkMaster);
    yield put({ type: SET_MASTER_LINKS, payload: result.data });
  } catch (err) {
    console.error(`Error fetching switched account`, action);
  }
}

function* requestDeleteMasterLink(action: Record<string, any>): any {
  try {
    yield call(deleteMasterLink, get(action, "payload._id", ""));
    yield put({
      type: SUCCESS_DELETE_MASTER_LINK,
      payload: get(action, "payload._id", ""),
    });
  } catch (error: any) {
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_DELETE_MASTER_LINK, payload: message });
  }
}

function* requestDeactiveMasterLink(action: Record<string, any>): any {
  try {
    yield call(
      deactiveMasterLink,
      get(action, "payload._id", ""),
      get(action, "payload.isDeactive", false)
    );
    yield put({
      type: SUCCESS_DEACTIVE_MASTER_LINK,
      payload: action.payload,
    });
  } catch (error: any) {
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_DEACTIVE_MASTER_LINK, payload: message });
  }
}

function* requestCreateMasterLink(action: Record<string, any>): any {
  try {
    const result = yield call(createMasterLink, action.payload);
    yield put({
      type: SUCCESS_CREATE_MASTER_LINK,
      payload: result.data,
    });
    // update relevant category as well
  } catch (error: any) {
    console.log(error);
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = error.response.data.message || "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_CREATE_MASTER_LINK, payload: message });
  }
}

function* requestUpdateMasterLink(action: Record<string, any>): any {
  try {
    const result = yield call(
      updateMasterLink,
      action.data.payload,
      action.data._id
    );
    yield put({
      type: SUCCESS_UPDATE_MASTER_LINK,
      payload: result.data,
    });
    // update relevant category as well
  } catch (error: any) {
    console.log(error);
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = error.response.data.message || "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_UPDATE_MASTER_LINK, payload: message });
  }
}

export function getLinkMaster() {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}/links`,
    withCredentials: true,
  });
}

export function deleteMasterLink(_id: string) {
  return axios({
    method: "delete",
    url: `${process.env.REACT_APP_BASE_URL}/links/${_id}`,
    withCredentials: true,
  });
}

export function deactiveMasterLink(_id: string, isDeactive: boolean) {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL}/links/${_id}`,
    withCredentials: true,
    data: { isDeactive: isDeactive },
  });
}

export function createMasterLink(payload: Record<string, any>) {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL}/links/`,
    data: payload,
    withCredentials: true,
  });
}

export function updateMasterLink(payload: Record<string, any>, _id: string) {
  return axios({
    method: "patch",
    url: `${process.env.REACT_APP_BASE_URL}/links/${_id}`,
    data: payload,
    withCredentials: true,
  });
}

const linkMasterSaga = function* () {
  yield all([
    takeLatest(REQUEST_MASTER_LINKS, requestMasterLinks),
    takeLatest(REQUEST_DELETE_MASTER_LINK, requestDeleteMasterLink),
    takeLatest(REQUEST_DEACTIVE_MASTER_LINK, requestDeactiveMasterLink),
    takeLatest(REQUEST_CREATE_MASTER_LINK, requestCreateMasterLink),
    takeLatest(REQUEST_UPDATE_MASTER_LINK, requestUpdateMasterLink),
  ]);
};

export default linkMasterSaga;
