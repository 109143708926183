import { useSelector } from "react-redux";

import {
    REQUEST_MASTER_ANALYTICS,
    SET_MASTER_ANALYTICS,
    ERROR_GET_MASTER_ANALYTICS,
} from "./analyticsActionTypes"

interface IState {
    busy: boolean;
    message: string;
    masterAnalytics: any[];
}

const initialState: IState =
{
    busy: false,
    message: "",
    masterAnalytics: [],
};

const analytics = (state = initialState, action: Record<string, any>) => {
    switch (action.type) {
        case REQUEST_MASTER_ANALYTICS:
            return {
                ...state,
                busy: true,
                message: "",
            };


        case SET_MASTER_ANALYTICS:
            return {
                ...state,
                busy: false,
                message: "",
                masterAnalytics: action.payload,
            };


        case ERROR_GET_MASTER_ANALYTICS:
            return {
                ...state,
                busy: false,
                message:
                    action.payload || "Something happened wrong try again after sometime",
            };
        default:
            return state;
    }
}

export default analytics;


export function useAnalyticsMaster() {
    return useSelector((state: Record<string, any>) => state.analytics);
}
