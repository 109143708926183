import { useEffect, useMemo } from "react";

import {
  Alert,
  AlertTitle,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { Form, Formik } from "formik";
import { get } from "lodash";
import Select from "react-select";
import { FormField } from "../form/formField/FormField";
import { usePromoCodeMaster } from "../../store/promoCode/reducer";
import { IPromoCode } from "../../types/promocde";
import { useCouponsMaster } from "../../store/coupon/reducer";
import {
  REQUEST_CREATE_MASTER_PROMOCODE,
  REQUEST_UPDATE_MASTER_PROMOCODE,
  SET_MESSAGE_MASTER_PROMOCODE,
} from "../../store/promoCode/promoCodeActionTypes";

interface Props {
  promocode?: IPromoCode;
  isOpen: any;
  onClose: any;
}

const addSchema = Yup.object({
  promotionCode: Yup.string().required("Required"),
  couponId: Yup.object().required("Required"),
});

const PromoCodeEditDrawer: React.FC<Props> = ({
  promocode,
  isOpen,
  onClose,
}) => {
  const { masterPromoCode, message, busy } = usePromoCodeMaster();
  const toast = useToast();
  const isDisabled = !!(promocode && promocode._id);
  const { masterCoupons } = useCouponsMaster();

  const dispatch = useDispatch();

  const couponOption = useMemo(
    () =>
      masterCoupons.map((lc: Record<string, any>) => {
        return {
          label: lc.couponId,
          value: lc.couponId,
        };
      }),
    [masterCoupons]
  );

  const initialValue = useMemo(() => {
    if (promocode) {
      return {
        couponId: {
          value: promocode.couponId,
          label: couponOption.find(
            (cat: any) => get(promocode, "couponId._id") === cat.value
          ).label,
        },
        promotionCode: promocode.promotionCode,
      };
    }
    return {
      couponId: "",
      promotionCode: "",
    };
  }, [promocode, couponOption]);

  const onSubmit = (values: Record<string, any>) => {
    if (promocode) {
      let payload: Record<string, any> = {
        active: !promocode.active,
      };
      const data = { payload, _id: promocode._id };
      dispatch({ type: REQUEST_UPDATE_MASTER_PROMOCODE, data });
    } else {
      let payload: Record<string, any> = {
        couponId: values.couponId.value,
        promotionCode: values.promotionCode,
      };
      dispatch({ type: REQUEST_CREATE_MASTER_PROMOCODE, payload });
    }
  };

  const promocodes = useMemo(() => {
    return masterPromoCode;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (promocodes.length !== masterPromoCode.length) {
      toast({
        title: "Success.",
        description: "Promocode is created.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      onClose();
    }
  }, [promocodes, masterPromoCode, promocode, onClose, toast]);

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={addSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, errors, touched, submitForm }) => (
        <Form>
          <Drawer
            isOpen={isOpen}
            placement="right"
            size={"md"}
            onClose={() => {
              onClose();
              dispatch({ type: SET_MESSAGE_MASTER_PROMOCODE });
            }}
            closeOnOverlayClick={busy}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader backgroundColor={"gray.100"} alignItems={"stretch"}>
                {message && (
                  <Alert status="error">
                    <AlertTitle>{message}</AlertTitle>
                  </Alert>
                )}
              </DrawerHeader>
              <DrawerBody>
                <FormControl isRequired>
                  <Flex width="100%">
                    <HStack>
                      <FormLabel>Coupon Id : </FormLabel>
                      <Spacer></Spacer>
                      <p>{errors.couponId}</p>
                    </HStack>
                  </Flex>
                  <Select
                    value={values["couponId"]}
                    name="couponId"
                    isDisabled={!!(isDisabled || promocode)}
                    onChange={(event) => {
                      if (event) {
                      }
                      //@ts-ignore
                      setFieldValue("couponId", event);
                    }}
                    options={couponOption}
                    placeholder="Select a Coupon"
                  />
                </FormControl>

                <FormField
                  disabled={!!(isDisabled || promocode)}
                  required={true}
                  name="Promo Code ID : "
                  fieldName="promotionCode"
                  type="string"
                  error={
                    touched.promotionCode
                      ? (errors.promotionCode as string)
                      : undefined
                  }
                />
              </DrawerBody>
              <DrawerFooter backgroundColor={"gray.100"}>
                <Button
                  variant="outline"
                  mr={3}
                  onClick={() => {
                    onClose();
                    dispatch({ type: SET_MESSAGE_MASTER_PROMOCODE });
                  }}
                  disabled={busy}
                >
                  Close
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={submitForm}
                  disabled={busy || isDisabled}
                >
                  {promocode && promocode._id ? null : "Save"}
                </Button>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </Form>
      )}
    </Formik>
  );
};

export default PromoCodeEditDrawer;
