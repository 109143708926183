import {
  Alert,
  AlertTitle,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  Spacer,
  Text,
  useToast,
} from "@chakra-ui/react";
import { get as _get } from "lodash";

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  ERROR_SEND_ADMIN_FEEDBACK,
  REQUEST_SEND_ADMIN_FEEDBACK,
} from "../../store/feedback/feedbackActionType";
import { useFeedback } from "../../store/feedback/reducer";
import { IFeedBack } from "../../types/feedback";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { FormField } from "../form/formField/FormField";
import { FormFieldEditor } from "../form/formField/FormFieldEditor";
import SelectFormField from "../form/SelectFormFields/SelectFormField";
import { IoMdDocument, IoMdDownload } from "react-icons/io";
interface Props {
  isOpen: any;
  onClose: any;
  feedbacks: IFeedBack;
}

const addSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address format")

    .required("Email is required"),
  subject: Yup.string().required("Required"),
  message: Yup.string().required("Required"),
  from: Yup.object().required("Required"),
});
const FeedbackReplyDrawer: React.FC<Props> = ({
  feedbacks,
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();

  const { busy, message, replySend } = useFeedback();
  const [isDisabled, setDisabled] = useState(!!(feedbacks && feedbacks._id));

  const emailOption: any[] = useMemo(() => {
    return [
      { value: "support@slynk.app", label: "support@slynk.app" },
      { value: "betasupport@slynk.app", label: "betasupport@slynk.app" },
      { value: "noreply@slynk.app", label: "noreply@slynk.app" },
      { value: "dev@slynk.app", label: "dev@slynk.app" },
      { value: "cameron@slynk.app", label: "cameron@slynk.app" },
      { value: "tess@slynk.app", label: "tess@slynk.app" },
      { value: "camlewis@slynk.app", label: "camlewis@slynk.app" },
    ];
  }, []);

  const initialValue = useMemo(() => {
    if (feedbacks) {
      return {
        email: feedbacks.email,
        from: emailOption[0],
        subject: "",
        message: "",
      };
    }
    return {
      from: emailOption[0],
      email: "",
      subject: "",
      message: "",
    };
  }, [emailOption, feedbacks]);

  const onSubmit = (values: Record<string, any>) => {
    dispatch({
      type: ERROR_SEND_ADMIN_FEEDBACK,
      payload: "none",
    });
    let payload: Record<string, any> = {
      email: values.email,
      from: values.from.value,
      subject: values.subject,
      message: values.message,
    };
    dispatch({ type: REQUEST_SEND_ADMIN_FEEDBACK, payload });
  };
  const toast = useToast();
  useEffect(() => {
    if (replySend) {
      toast({
        title: "Success.",
        description: "Reply has been Successfully Sent.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      onClose();
    }
  }, [replySend, toast, onClose]);

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        size={"md"}
        onClose={onClose}
        closeOnOverlayClick={busy}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader backgroundColor={"gray.100"} alignItems={"stretch"}>
            <Flex>
              <Spacer></Spacer>
              {isDisabled ? (
                <Button
                  onClick={() => {
                    dispatch({
                      type: ERROR_SEND_ADMIN_FEEDBACK,
                      payload: "none",
                    });
                    setDisabled(!isDisabled);
                  }}
                >
                  Reply
                </Button>
              ) : (
                <Button
                  disabled={busy}
                  onClick={() => {
                    setDisabled(!isDisabled);
                  }}
                >
                  Show Mail
                </Button>
              )}
            </Flex>

            {message && (
              <Alert status="error">
                <AlertTitle>{message}</AlertTitle>
              </Alert>
            )}
          </DrawerHeader>
          <DrawerBody>
            {isDisabled ? (
              <>
                <Text as="samp" fontSize="20px">
                  Name :
                </Text>
                <Text>
                  {_get(feedbacks, "accountId.firstName") ||
                  _get(feedbacks, "accountId.firstName") !== undefined
                    ? _get(feedbacks, "accountId.firstName") +
                      " " +
                      _get(feedbacks, "accountId.lastName")
                    : _get(feedbacks, "accountId.BusinessName") ||
                      _get(feedbacks, "accountId.BusinessName") !== undefined
                    ? _get(feedbacks, "accountId.BusinessName")
                    : "N/A"}
                </Text>
                <Text as="samp" fontSize="20px">
                  Sender :
                </Text>
                <Text>{feedbacks.email}</Text>
                <Text as="samp" fontSize="20px">
                  Message Type :
                </Text>
                <Text>{_get(feedbacks, "messageType.title")}</Text>
                <Text as="samp" fontSize="20px">
                  Message :
                </Text>
                <Text>{feedbacks.message}</Text>
                <Text as="samp" fontSize="20px">
                  Attachment :
                </Text>
                <Flex gap={2} flexWrap="wrap" mt={15}>
                  {feedbacks.attachment?.map((item: any, index: number) => {
                    return (
                      <>
                        <Center>
                          <Center
                            verticalAlign={"center"}
                            height={"100px"}
                            width={"100px"}
                            cursor={"pointer"}
                            backgroundColor={"gray.100"}
                            backgroundSize={"cover"}
                            onClick={() => {
                              window.open(item.url, "_blank");
                            }}
                          >
                            {item.mimeType.includes("image") ? (
                              <Image
                                src={item.url}
                                key={index}
                                alt="Feedback"
                                boxSize="100px"
                                borderRadius={15}
                                cursor={"pointer"}
                                objectFit="cover"
                                onClick={() => {
                                  window.open(item.url, "_blank");
                                }}
                              />
                            ) : item.mimeType.includes("video") ? (
                              <>
                                <Flex flexDirection={"column"}>
                                  <Center>
                                    <Text>Video</Text>
                                  </Center>
                                  <video
                                    src={item.url}
                                    style={{ height: "70px" }}
                                  />
                                </Flex>
                              </>
                            ) : (
                              <IoMdDocument
                                // style={{ position: "absolute" }}
                                color={"#3a1bdb"}
                                size="100%"
                                opacity={0.5}
                              />
                            )}
                            <IoMdDownload
                              style={{ position: "absolute" }}
                              color={"gray.600"}
                              size="35px"
                              opacity={0.5}
                            />
                          </Center>
                        </Center>
                      </>
                    );
                  })}
                </Flex>
              </>
            ) : (
              <>
                <Formik
                  initialValues={initialValue}
                  validationSchema={addSchema}
                  onSubmit={onSubmit}
                >
                  {({ values, setFieldValue, errors, touched, submitForm }) => (
                    <Form>
                      <SelectFormField
                        value={values["from"]}
                        options={emailOption}
                        required={true}
                        name={"From : "}
                        fieldName={"from"}
                        isMulti={false}
                        placeholder={"Select a From"}
                        error={
                          touched.from ? (errors.from as string) : undefined
                        }
                      />

                      <FormField
                        disabled={true}
                        required={true}
                        name="To : "
                        fieldName="email"
                        type="email"
                        error={
                          touched.email ? (errors.email as string) : undefined
                        }
                      />
                      <FormField
                        disabled={isDisabled}
                        required={true}
                        name="Subject : "
                        fieldName="subject"
                        type="text"
                        error={
                          touched.subject
                            ? (errors.subject as string)
                            : undefined
                        }
                      />
                      <FormFieldEditor
                        disabled={isDisabled}
                        required={true}
                        name="Message : "
                        fieldName="message"
                        type="textarea"
                        error={
                          touched.message
                            ? (errors.message as string)
                            : undefined
                        }
                      />
                      <Button
                        colorScheme="blue"
                        onClick={submitForm}
                        disabled={busy}
                        mt="5"
                      >
                        Send
                      </Button>
                    </Form>
                  )}
                </Formik>
              </>
            )}
          </DrawerBody>
          <DrawerFooter backgroundColor={"gray.100"}>
            <Button variant="outline" mr={3} onClick={onClose} disabled={busy}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default FeedbackReplyDrawer;
