import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  CloseButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { REQUEST_DELETE_DELETEUSER } from "../../store/deleteUser/deleteUserAction";
import { useDeleteUsers } from "../../store/deleteUser/reducer";
import { IUser } from "../../types/user";
interface Props {
  deleteuser: IUser;
  isOpen: any;
  onClose: any;
}
const DeleteUserPerDelete: React.FC<Props> = ({
  isOpen,
  onClose,
  deleteuser,
}) => {
  const dispatch = useDispatch();
  const { deleteusers, busy, message } = useDeleteUsers();
  const { _id } = deleteuser;
  const toast = useToast();

  useEffect(() => {
    const findLink = deleteusers.find(
      (val: Record<string, any>) => val._id === _id
    );
    if (!findLink) {
      toast({
        title: "Permenant Deleted",
        description: "User is successfully permenant deleted.",
        status: "info",
        isClosable: true,
        position: "top-right",
      });
      onClose();
    }
  }, [_id, deleteusers, onClose, toast]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Permenant Delete User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontWeight="bold" mb="1rem">
            Are you sure to permenant delete this user? {deleteuser.email}
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme="red"
            variant={"outline"}
            mr={3}
            disabled={busy}
            onClick={() => {
              dispatch({
                type: REQUEST_DELETE_DELETEUSER,
                payload: { _id: deleteuser._id },
              });
            }}
          >
            Submit
          </Button>
        </ModalFooter>{" "}
        {message && (
          <ModalFooter>
            <Alert status="error">
              <AlertIcon />
              <AlertTitle mr={2}>{message}</AlertTitle>
              <CloseButton position="absolute" right="8px" top="8px" />
            </Alert>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
export default DeleteUserPerDelete;
