import { combineReducers } from "@reduxjs/toolkit";
import linksMaster from "./linkMaster/reducer";
import auth from "./auth/reducer";
import accounts from "./account/reducer";
import betaUsers from "./betaUser/reducer";
import linkCategories from "./linkCategory/reducer";
import users from "./user/reducer";
import feedbacks from "./feedback/reducer";
import feedbackMessageType from "./feedbackMessageType/reducer";
import products from "./product/reducer";
import coupons from "./coupon/reducer";
import promocode from "./promoCode/reducer";
import mobileproducts from "./mobileproduct/reducer";
import mobilecoupons from "./mobilecoupon/reducer";
import tutorials from "./tutorial/reducer";
import faqs from "./faq/reducer";
import tags from "./tag/reducer";
import report from "./report/reducer";
import analytics from "./analytics/reducer";
import instruction from "./Instruction/reducer";
import deleteusers from "./deleteUser/reducer";
import deletetags from "./deleteTag/reducer";

export default combineReducers({
  auth,
  linksMaster,
  accounts,
  betaUsers,
  linkCategories,
  users,
  feedbacks,
  feedbackMessageType,
  products,
  coupons,
  report,
  promocode,
  mobileproducts,
  mobilecoupons,
  tutorials,
  faqs,
  tags,
  analytics,
  instruction,
  deleteusers,
  deletetags,
});
