import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { get } from "lodash";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { REQUEST_UPDATE_FEEDBACK_DETAIL } from "../../store/feedback/feedbackActionType";
import { useFaqsMaster } from "../../store/faq/reducer";
import { IFeedBack } from "../../types/feedback";
interface Props {
  isOpen: any;
  onClose: any;
  feedback: IFeedBack;
}
const ArchiveFeedbackConformation: React.FC<Props> = ({
  isOpen,
  onClose,
  feedback,
}) => {
  const { masterFaqs, message, busy } = useFaqsMaster();

  const dispatch = useDispatch();
  const toast = useToast();

  useEffect(() => {
    // if (
    //   !busy &&
    //   !message &&
    //   !(masterFaqs || []).find(
    //     (fb: Record<string, any>) => fb._id === feedback._id
    //   )
    // ) {
    //   onClose();
    // }
  }, [feedback._id, masterFaqs, busy, message, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Message Archive Conformation</ModalHeader>
        <ModalCloseButton disabled={busy} />
        <ModalBody>
          <Text color="red">
            {get(feedback, "isArchived")
              ? "Are sure to remove from archive this message ? "
              : "Are sure to archive this message ? "}
          </Text>
          <Text>
            Message belongs to this email : {get(feedback, "userId.email")}
          </Text>
          {message && (
            <Alert status="error" zIndex={100}>
              <AlertIcon />
              <AlertTitle mr={2}>{message}</AlertTitle>
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose} disabled={busy}>
            Close
          </Button>
          <Button
            colorScheme="red"
            variant={"outline"}
            mr={3}
            onClick={() => {
              dispatch({
                type: REQUEST_UPDATE_FEEDBACK_DETAIL,
                data: {
                  _id: get(feedback, "_id"),
                  payload: {
                    isArchived: !feedback.isArchived,
                  },
                },
                onSuccess: () => {
                  toast({
                    title: !get(feedback, "isArchived")
                      ? "Message Archived"
                      : "Message UnArchived",
                    status: !get(feedback, "isArchived")
                      ? "success"
                      : "warning",
                    duration: 3000,
                    position: "top",
                    isClosable: true,
                  });
                  onClose();
                },
              });
            }}
            disabled={busy}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default ArchiveFeedbackConformation;
