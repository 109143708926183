export const REQUEST_DELETETAG = "REQUEST_DELETETAG";
export const SET_DELETETAG = "SET_DELETETAG";
export const ERROR_DELETETAG = "ERROR_DELETETAG";

export const REQUEST_DELETE_DELETETAG = "REQUEST_DELETE_DELETETAG";
export const SUCCESS_DELETE_DELETETAG = "SUCCESS_DELETE_DELETETAG";
export const ERROR_DELETE_DELETETAG = "ERROR_DELETE_DELETETAG";

export const REQUEST_RECOVER_DELETETAG = "REQUEST_RECOVER_DELETETAG";
export const SUCCESS_RECOVER_DELETETAG = "SUCCESS_RECOVER_DELETETAG";
export const ERROR_RECOVER_DELETETAG = "ERROR_RECOVER_DELETETAG";

export const REQUEST_SORTED_DELETETAG = "REQUEST_SORTED_DELETETAG";
export const SET_SORTED_DELETETAG = "SET_SORTED_DELETETAG";
export const ERROR_GET_SORTED_DELETETAG = "ERROR_GET_SORTED_DELETETAG";
