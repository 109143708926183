export const REQUEST_ACCOUNTS = "REQUEST_ACCOUNTS";
export const SET_ACCOUNTS = "SET_ACCOUNTS";
export const DELETE_ACCOUNT_REQUEST = "DELETE_ACCOUNT_REQUEST";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

export const REQUEST_ACCOUNT_VERIFY = "REQUEST_ACCOUNT_VERIFY";
export const SUCCESS_ACCOUNT_VERIFY = "SUCCESS_ACCOUNT_VERIFY";
export const ERROR_ACCOUNT_VERIFY = "ERROR_ACCOUNT_VERIFY";

export const REQUEST_UPDATE_ACCOUNT = "REQUEST_UPDATE_ACCOUNT";
