import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Flex,
  IconButton,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { Spin, Table } from "antd";
import { get } from "lodash";
import { useDispatch } from "react-redux";
import { IUser } from "../../types/user";
import { useAuth } from "../../store/auth/reducer";
import moment from "moment";
import { FcGoogle } from "react-icons/fc";
import { REQUEST_DELETEUSER } from "../../store/deleteUser/deleteUserAction";
import { useDeleteUsers } from "../../store/deleteUser/reducer";
import DeleteUserPerDelete from "../modals/DeleteUserPerDelete";
import DeleteUserRecover from "../modals/DeleteUserRecover";

export interface User {
  searchQuery: string;
  setCSVData: any;
  startDate: any;
}

const UserDeletedTable: React.FC<User> = ({
  searchQuery: query,
  setCSVData,
  startDate,
}) => {
  const { deleteusers, busy } = useDeleteUsers();
  const [deleteData, setDeleteData] = useState<IUser>();
  const { adminUser } = useAuth();
  const searchColumns = useMemo(
    () => ["firstName", "lastName", "email", "phoneNumber", "gender"],
    []
  );

  const [page, setPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(1);

  const search = (user: IUser) => {
    const tempQuery = query.split(" ");

    return tempQuery.every((item) => {
      return searchColumns.some((column) => {
        return (
          get(user, column, "")
            .toString()
            .toLowerCase()
            .indexOf(item.toLowerCase()) > -1
        );
      });
    });
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: REQUEST_DELETEUSER });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCSVData(
      deleteusers.map((value: any) => {
        return {
          EMAIL: value.email,
          FIRSTNAME: value.firstName ? value.firstName : "Nan",
          LASTNAME: value.lastName ? value.lastName : "Nan",
          PHONENUMBER: value.phoneNumber ? value.phoneNumber : "",
          DOB: value.DOB ? moment(value.DOB).format("MM/DD/YYYY") : "",
          CREATION_DATE: value.createdAt
            ? moment(value.createdAt).format("MM/DD/YYYY")
            : moment("2021-07-25T09:52:07.574Z").format("MM/DD/YYYY"),
          GENDER: value.gender ? value.gender : "",
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const deleteFunction = (dataDelete: IUser) => {
    setDeleteData(dataDelete);
    onDeleteOpen();
  };

  const {
    isOpen: isRecoverOpen,
    onOpen: onRecoverOpen,
    onClose: onRecoverClose,
  } = useDisclosure();

  const recoverFunction = (dataDelete: IUser) => {
    setDeleteData(dataDelete);
    onRecoverOpen();
  };

  const {
    isOpen: isProOpen,
    onOpen: onProOpen,
    onClose: onProClose,
  } = useDisclosure();

  const [proData, setProData] = useState<IUser>();
  const [isPro, setIsPro] = useState<boolean>(false);

  const openProDataModal = (value: IUser) => {
    setProData(value);
    setIsPro(true);
    onProOpen();
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (
      startDate[0] === "" ||
      (startDate[0] === "Invalid date" && startDate[1] === "") ||
      startDate[1] === "Invalid date"
    ) {
      setData(
        deleteusers.filter(search).map((value: IUser, index: number) => ({
          _id: value._id,
          key: index + 1,
          Index: index + 1,
          email: value.email,
          firstName: value.firstName ? value.firstName : "Nan",
          lastName: value.lastName ? value.lastName : "Nan",
          phoneNumber: value.phoneNumber ? value.phoneNumber : "",
          gender: value.gender ? value.gender : "",
          subscriptionTill: value.subscriptionTill
            ? moment(value.subscriptionTill).format("MM/DD/YYYY")
            : "N/A",
          DOB: value.DOB ? moment(value.DOB).format("MM/DD/YYYY") : "Nan",
          createdAt: value.createdAt
            ? moment(value.createdAt).format("MM/DD/YYYY")
            : moment("2022-02-25T09:52:07.574Z").format("MM/DD/YYYY"),
          value: value,
        }))
      );
    } else {
      setData(
        deleteusers
          .filter((item: any) => {
            // console.log(moment(item.createdAt).format("MM/DD/YYYY") > startDate[1])
            return (
              moment(item.createdAt) > moment(startDate[0]) &&
              moment(item.createdAt) < moment(startDate[1])
            );
          })
          .filter(search)
          .map((value: IUser, index: number) => ({
            _id: value._id,
            key: index + 1,
            Index: index + 1,
            email: value.email,
            firstName: value.firstName ? value.firstName : "",
            lastName: value.lastName ? value.lastName : "",
            phoneNumber: value.phoneNumber ? value.phoneNumber : "",
            gender: value.gender ? value.gender : "",
            subscriptionTill: value.subscriptionTill
              ? moment(value.subscriptionTill).format("MM/DD/YYYY")
              : "N/A",
            DOB: value.DOB ? moment(value.DOB).format("MM/DD/YYYY") : "Nan",
            createdAt: value.createdAt
              ? moment(value.createdAt).format("MM/DD/YYYY")
              : moment("2022-02-25T09:52:07.574Z").format("MM/DD/YYYY"),
            value: value,
          }))
      );
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteusers, startDate, query]);

  const columns = [
    {
      title: "SR N0.",
      width: 75,
      render: (text: string, record: any, index: number) =>
        (page - 1) * paginationSize + index + 1,
    },
    {
      title: "ACTION",
      dataIndex: "value",
      width: 340,
      render: (value: any) => (
        <>
          <Button
            // ml={3}
            onClick={() => {
              deleteFunction(value);
            }}
          >
            Permenant Delete
          </Button>
          <Button
            ml={3}
            onClick={() => {
              recoverFunction(value);
            }}
          >
            Recover
          </Button>
        </>
      ),
    },
    {
      title: "FIRSTNAME",
      dataIndex: "firstName",
      width: 120,
      sorter: (a: any, b: any) => a.firstName.localeCompare(b.firstName),
    },
    {
      title: "LASTNAME",
      dataIndex: "lastName",
      width: 120,
      sorter: (a: any, b: any) => a.lastName.localeCompare(b.lastName),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      width: 250,
      render: (email: any, value: any) => (
        <>
          <Flex alignItems="center">
            <Text>{email}</Text>
            {value.value.googleLogin ? (
              <FcGoogle style={{ marginLeft: "10px" }} />
            ) : (
              ""
            )}
          </Flex>
        </>
      ),
    },
    {
      title: "PHONE",
      dataIndex: "phoneNumber",
      width: 150,
    },
    {
      title: "GENDER",
      dataIndex: "gender",
      width: 100,
    },
    {
      title: "DOB",
      dataIndex: "DOB",
      width: 120,
      sorter: (a: any, b: any) =>
        moment(a.DOB, "MM/DD/YYYY").unix() - moment(b.DOB, "MM/DD/YYYY").unix(),
    },
    {
      title: "CREATION DATE",
      dataIndex: "createdAt",
      width: 120,
      sorter: (a: any, b: any) =>
        moment(a.createdAt, "MM/DD/YYYY").unix() -
        moment(b.createdAt, "MM/DD/YYYY").unix(),
    },
    {
      title: "EXPIRY DATE",
      dataIndex: "subscriptionTill",
      width: 120,
      sorter: (a: any, b: any) =>
        moment(a.subscriptionTill, "MM/DD/YYYY").unix() -
        moment(b.subscriptionTill, "MM/DD/YYYY").unix(),
    },
  ];

  return (
    <>
      <div
        style={{
          width: "96vw",
          maxWidth: "96vw",
          height: "90vh",
          top: "6vh ",
          left: "2vw",
          position: "relative",
        }}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            loading={busy && deleteusers.length === 0 ? true : false}
            scroll={{ y: "calc(68vh - 2em)", x: 1400 }}
            rowClassName={() => "gray"}
            pagination={{
              onChange(current, pageSize) {
                setPage(current);
                setPaginationSize(pageSize);
              },
              hideOnSinglePage: true,
              showSizeChanger: true,
            }}
          />
        )}
      </div>
      {isDeleteOpen && deleteData && (
        <DeleteUserPerDelete
          deleteuser={deleteData}
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
        />
      )}
      {isRecoverOpen && deleteData && (
        <DeleteUserRecover
          deleteuser={deleteData}
          isOpen={isRecoverOpen}
          onClose={onRecoverClose}
        />
      )}
    </>
  );
};

export default UserDeletedTable;
