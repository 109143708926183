import axios from "axios";
import { get } from "lodash";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  REQUEST_MASTER_TAG,
  REQUEST_CREATE_MASTER_TAG,
  REQUEST_SORTED_TAG,
  SET_MASTER_TAG,
  SUCCESS_CREATE_MASTER_TAG,
  SET_SORTED_TAG,
  ERROR_GET_MASTER_TAG,
  ERROR_CREATE_MASTER_TAG,
  ERROR_GET_SORTED_TAG,
  REQUEST_DELETE_MASTER_TAG,
  SUCCESS_DELETE_MASTER_TAG,
  ERROR_DELETE_MASTER_TAG,
} from "./tagActionTypes";

function* requestMasterTag(action: Record<string, any>): any {
  try {
    const result: any = yield call(getTagMaster, action.data);
    yield put({ type: SET_MASTER_TAG, payload: result.data });
    // console.warn(result.data);
    action && action.onSuccess && action.onSuccess();
  } catch (error: any) {
    console.log(error);
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = error.response.data.message || "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_GET_MASTER_TAG, payload: message });
  }
}

function* requestCreateMasterTag(action: Record<string, any>): any {
  try {
    const result = yield call(createMasterTag, action.payload);
    yield put({
      type: SUCCESS_CREATE_MASTER_TAG,
      payload: result.data,
    });
    // update relevant category as well
  } catch (error: any) {
    console.log(error);
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = error.response.data.message || "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_CREATE_MASTER_TAG, payload: message });
  }
}

function* requestSortedTag(action: Record<string, any>): any {
  try {
    const result = yield call(sortedTag, action.data);
    yield put({
      type: SET_SORTED_TAG,
      payload: result.data,
    });
    action && action.onSuccess && action.onSuccess();

    // update relevant category as well
  } catch (error: any) {
    console.log(error);
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = error.response.data.message || "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_GET_SORTED_TAG, payload: message });
  }
}

function* requestDeleteMasterTag(action: Record<string, any>): any {
  try {
    yield call(deleteMasterTag, get(action, "payload._id", ""));
    yield put({
      type: SUCCESS_DELETE_MASTER_TAG,
      payload: get(action, "payload._id", ""),
    });
  } catch (error: any) {
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_DELETE_MASTER_TAG, payload: message });
  }
}

export function getTagMaster(action: any) {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/tags/?page=${action.page}&limit=${action.pageSize}`,
    withCredentials: true,
  });
}

export function createMasterTag(payload: Record<string, any>) {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL}/tags`,
    data: payload,
    withCredentials: true,
  });
}

export function sortedTag(payload: Record<string, any>) {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL}/tags/getSortedTag`,
    data: payload,
    withCredentials: true,
  });
}

export function deleteMasterTag(_id: string) {
  return axios({
    method: "delete",
    url: `${process.env.REACT_APP_BASE_URL}/tags`,
    data: { id: _id },
    withCredentials: true,
  });
}

const tagMasterSaga = function* () {
  yield all([
    takeLatest(REQUEST_MASTER_TAG, requestMasterTag),
    takeLatest(REQUEST_CREATE_MASTER_TAG, requestCreateMasterTag),
    takeLatest(REQUEST_DELETE_MASTER_TAG, requestDeleteMasterTag),
    takeLatest(REQUEST_SORTED_TAG, requestSortedTag),
  ]);
};

export default tagMasterSaga;
