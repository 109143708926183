import axios from "axios";
import { get } from "lodash";
/**
 * 
 * @param userId 
 */

export const getAuthToken = (userId: string) => {
  axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL}/auth/login_with_admin/${userId}`,
    withCredentials: true,
  })
    .then((res) => {
      if (get(res, "data.token")) {
        window.open(
          `${process.env.REACT_APP_FRONTEND_URL}/?token=${encodeURIComponent(
            res.data.token
          )}`,
          "_blank"
        );
      } else {
        alert("Something went wrong");
        // setErrorMsg(
        //   "Something went wrong, please try again after some time or Refresh the Page."
        // );
      }
    })
    .catch((error) => {
      alert("Something went wrong");
      //   setErrorMsg(
      //     get(error, "response.data.message") ??
      //       "Something went wrong, please try again after some time or Refresh the Page."
      //   );
    });
};
