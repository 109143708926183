
import { useSelector } from "react-redux";

import {
  REQUEST_MASTER_REPORT,
  SET_MASTER_REPORT,
  ERROR_GET_MASTER_REPORT,
  SET_MESSAGE_MASTER_REPORT,
} from "./reportActionTypes";
import { IReport } from "../../types/report";

interface IState {
  busy: boolean;
  message: string;
  masterReport: IReport[];
}

const initialState: IState =
{
    busy: false,
    message: "",
    masterReport: [],
};

const report = (state = initialState, action: Record<string, any>) => {
    switch (action.type) {
        case REQUEST_MASTER_REPORT:
            return {
                ...state,
                busy: true,
                message: "",
            };

        case SET_MESSAGE_MASTER_REPORT:
            return {
                ...state,
                message: "",
            };

        case SET_MASTER_REPORT:
            return {
                ...state,
                busy: false,
                message: "",
                masterReport: action.payload,
            };

        case ERROR_GET_MASTER_REPORT:
            return {
                ...state,
                busy: false,
                message:
                    action.payload || "Something happened wrong try again after sometime",
            };
        default:
            return state;
    }
}

export default report;


export function useReportMaster() {
    return useSelector((state: Record<string, any>) => state.report);
}
