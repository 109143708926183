import axios from "axios";
import { get } from "lodash";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  REQUEST_DELETETAG,
  SET_DELETETAG,
  REQUEST_DELETE_DELETETAG,
  SUCCESS_DELETE_DELETETAG,
  ERROR_DELETE_DELETETAG,
  SUCCESS_RECOVER_DELETETAG,
  ERROR_RECOVER_DELETETAG,
  REQUEST_RECOVER_DELETETAG,
  ERROR_DELETETAG,
  REQUEST_SORTED_DELETETAG,
  ERROR_GET_SORTED_DELETETAG,
  SET_SORTED_DELETETAG,
} from "./deleteTagAction";
function* requestTag(action: Record<string, any>): any {
  try {
    const result: any = yield call(geTagMaster);
    yield put({ type: SET_DELETETAG, payload: result.data });
  } catch (error: any) {
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_DELETETAG, payload: message });
  }
}

function* requestDeleteMasterTag(action: Record<string, any>): any {
  try {
    yield call(deleteMasterTag, get(action, "payload._id", ""));
    yield put({
      type: SUCCESS_DELETE_DELETETAG,
      payload: get(action, "payload._id", ""),
    });
  } catch (error: any) {
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_DELETE_DELETETAG, payload: message });
  }
}

function* requestRecoverMasterTag(action: Record<string, any>): any {
  try {
    yield call(recoverMasterTag, get(action, "payload._id", ""));
    yield put({
      type: SUCCESS_RECOVER_DELETETAG,
      payload: get(action, "payload._id", ""),
    });
  } catch (error: any) {
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_RECOVER_DELETETAG, payload: message });
  }
}

function* requestSortedTag(action: Record<string, any>): any {
  try {
    const result = yield call(sortedTag, action.data);
    yield put({
      type: SET_SORTED_DELETETAG,
      payload: result.data.tags,
    });
    action && action.onSuccess && action.onSuccess();

    // update relevant category as well
  } catch (error: any) {
    console.log(error);
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = error.response.data.message || "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_GET_SORTED_DELETETAG, payload: message });
  }
}

export function geTagMaster() {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/tags/deleteTag`,
    withCredentials: true,
  });
}

export function deleteMasterTag(_id: string) {
  return axios({
    method: "delete",
    url: `${process.env.REACT_APP_BASE_URL}/tags/deleteTag/${_id}`,
    withCredentials: true,
  });
}

export function sortedTag(payload: Record<string, any>) {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL}/tags/getSortedTag`,
    data: payload,
    withCredentials: true,
  });
}

export function recoverMasterTag(_id: string) {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL}/tags/recover/${_id}`,
    withCredentials: true,
  });
}

const deleteTagSaga = function* () {
  yield all([
    takeLatest(REQUEST_DELETETAG, requestTag),
    takeLatest(REQUEST_DELETE_DELETETAG, requestDeleteMasterTag),
    takeLatest(REQUEST_RECOVER_DELETETAG, requestRecoverMasterTag),
    takeLatest(REQUEST_SORTED_DELETETAG, requestSortedTag),
  ]);
};
export default deleteTagSaga;
