import React from "react";
import {
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  IconButton,
  Flex,
  Spacer,
  VStack,
} from "@chakra-ui/react";
import { FiMenu, FiCalendar, FiUser } from "react-icons/fi";

import {
  MdAnalytics,
  MdCategory,
  MdFeedback,
  MdOutlineProductionQuantityLimits,
  MdTurnedIn,
} from "react-icons/md";

import { Link } from "react-router-dom";
import NavItem from "./NavItem";
import { BiLogOut } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { REQUEST_LOGOUT_USER } from "../../store/auth/authActionType";
import { FaQuora } from "react-icons/fa";
import { useAuth } from "../../store/auth/reducer";
export default function Sidebar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const dispatch = useDispatch();
  const { adminUser } = useAuth();

  return (
    <>
      <IconButton
        icon={<FiMenu />}
        //@ts-ignore
        ref={btnRef}
        onClick={onOpen}
        top={5}
        left={5}
        bg="transparent"
        position="absolute"
        zIndex={100}
      ></IconButton>

      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        //@ts-ignore
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton _focus={{ border: "none" }} />
          <DrawerHeader borderBottomWidth="1px">
            Slynk ( {adminUser?.userType} )
          </DrawerHeader>

          <DrawerBody onClick={onClose}>
            <Flex height="100%">
              <VStack alignItems="baseline">
                <Link to="/">
                  <NavItem icon={FiCalendar} title="Account" />
                </Link>
                <Link to="/user">
                  <NavItem icon={FiUser} title="User" />
                </Link>
                <Link to="/analytics">
                  <NavItem icon={MdAnalytics} title="Analytics" />
                </Link>
                <Link to="/links">
                  <NavItem icon={MdCategory} title="Links" />
                </Link>

                {/* <Link to="/user">
                  <NavItem icon={FiUser} title="Users" />
                </Link> */}

                <Link to="/messageCenter">
                  <NavItem icon={MdFeedback} title="Message Center" />
                </Link>

                <Link to="/products">
                  <NavItem
                    icon={MdOutlineProductionQuantityLimits}
                    title="Products"
                  />
                </Link>

                <Link to="/coupon">
                  <NavItem
                    icon={MdOutlineProductionQuantityLimits}
                    title="Coupon"
                  />
                </Link>

                <Link to="/promcode">
                  <NavItem
                    icon={MdOutlineProductionQuantityLimits}
                    title="Promocode"
                  />
                </Link>

                <Link to="/tag">
                  <NavItem
                    icon={MdOutlineProductionQuantityLimits}
                    title="Tag"
                  />
                </Link>

                {/* <Link to="/mobileproducts">
                  <NavItem
                    icon={MdOutlineProductionQuantityLimits}
                    title="IMobile Products"
                  />
                </Link>
                <Link to="/mobilecoupons">
                  <NavItem
                    icon={MdOutlineProductionQuantityLimits}
                    title="IMobile Coupons"
                  />
                </Link> */}

                <Link to="/tutorial">
                  <NavItem icon={MdTurnedIn} title="Tutorials" />
                </Link>

                <Link to="/faq">
                  <NavItem icon={FaQuora} title="Faq" />
                </Link>

                <Link to="/report">
                  <NavItem icon={FaQuora} title="Report" />
                </Link>
                <Link to="/instruction">
                  <NavItem
                    icon={MdOutlineProductionQuantityLimits}
                    title="Instruction"
                  />
                </Link>

                <Spacer></Spacer>

                <IconButton
                  icon={<NavItem icon={BiLogOut} title="Logout" />}
                  aria-label="Logout"
                  title="LogOut"
                  onClick={() => {
                    localStorage.clear();
                    window.location.reload();
                    dispatch({ type: REQUEST_LOGOUT_USER });
                  }}
                />
              </VStack>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
