export const REQUEST_DELETEUSER = "REQUEST_DELETEUSER";
export const SET_DELETEUSER = "SET_DELETEUSER";
export const ERROR_DELETEUSER = "ERROR_DELETEUSER";

export const REQUEST_DELETE_DELETEUSER = "REQUEST_DELETE_DELETEUSER";
export const SUCCESS_DELETE_DELETEUSER = "SUCCESS_DELETE_DELETEUSER";
export const ERROR_DELETE_DELETEUSER = "ERROR_DELETE_DELETEUSER";

export const REQUEST_RECOVER_DELETEUSER = "REQUEST_RECOVER_DELETEUSER";
export const SUCCESS_RECOVER_DELETEUSER = "SUCCESS_RECOVER_DELETEUSER";
export const ERROR_RECOVER_DELETEUSER = "ERROR_RECOVER_DELETEUSER";
