import axios from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  REQUEST_LINK_CATEGORY,
  SET_LINK_CATEGORY,
} from "./linkCategoryActionType";

function* requestLinkCategory(action: Record<string, any>): any {
  try {
    const result: any = yield call(getLinkCategory);
    yield put({ type: SET_LINK_CATEGORY, payload: result.data });
  } catch (err) {
    console.error(`Error fetching switched account`, action);
  }
}
export function getLinkCategory() {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}/links_category/`,
    withCredentials: true,
  });
}

const linkCategorySaga = function* () {
  yield all([takeLatest(REQUEST_LINK_CATEGORY, requestLinkCategory)]);
};

export default linkCategorySaga;
