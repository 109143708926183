import axios from "axios";
import { get } from "lodash";
import { all, put, takeLatest, call } from "redux-saga/effects";
import {
  REQUEST_ACCOUNTS,
  SET_ACCOUNTS,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  REQUEST_ACCOUNT_VERIFY,
  SUCCESS_ACCOUNT_VERIFY,
  ERROR_ACCOUNT_VERIFY,
} from "./accountActionType";

function* requestAccounts(action: Record<string, any>): any {
  try {
    const result: any = yield call(getAccounts);
    yield put({ type: SET_ACCOUNTS, payload: result.data });
  } catch (err) {
    console.error(`Error fetching switched account`, action);
  }
}

function* requestDeleteUser(action: Record<string, any>): any {
  try {
    yield call(deleteUser, get(action, "payload.userId", ""));
    yield put({
      type: DELETE_USER_SUCCESS,
      payload: get(action, "payload.userId", ""),
    });
  } catch (error: any) {
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: SET_ACCOUNTS, payload: message });
  }
}

function* requestVerifyAccount(action: Record<string, any>): any {
  try {
    yield call(verifyAccount, get(action, "payload.accountId", ""));

    yield put({
      type: SUCCESS_ACCOUNT_VERIFY,
      payload: action.payload,
    });
    action && action.onSuccess && action.onSuccess();
  } catch (error: any) {
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_ACCOUNT_VERIFY, payload: message });
  }
}

export function getAccounts() {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/account`,
    withCredentials: true,
  });
}

export function deleteUser(_id: string) {
  return axios({
    method: "delete",
    url: `${process.env.REACT_APP_BASE_URL}/user/${_id}`,
    withCredentials: true,
  });
}

export function verifyAccount(_id: string) {
  return axios({
    method: "put",
    url: `${process.env.REACT_APP_BASE_URL}/account/${_id}/verify`,
    withCredentials: true,
  });
}

const accountSaga = function* () {
  yield all([
    takeLatest(REQUEST_ACCOUNTS, requestAccounts),
    takeLatest(DELETE_USER_REQUEST, requestDeleteUser),
    takeLatest(REQUEST_ACCOUNT_VERIFY, requestVerifyAccount),
  ]);
};

export default accountSaga;
