import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Drawer,
  DrawerBody,
  IconButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Radio,
  RadioGroup,
  Spacer,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import Select from "react-select";
import { Form, Formik } from "formik";
import { get } from "lodash";
import { useLinksMaster } from "../../store/linkMaster/reducer";
import * as Yup from "yup";
import { FormField } from "../form/formField/FormField";
import { useEffect, useMemo, useState } from "react";
import { useLinkCategories } from "../../store/linkCategory/reducer";
import { useDispatch } from "react-redux";
import {
  REQUEST_CREATE_MASTER_LINK,
  REQUEST_UPDATE_MASTER_LINK,
} from "../../store/linkMaster/linkActionTypes";
import { MdModeEditOutline } from "react-icons/md";
import ImageFormField from "../ImageFormField/ImageFormField";
import { ILinks } from "../../types/links";
interface Props {
  link?: ILinks;
  isOpen: any;
  onClose: any;
}

// const FILE_SIZE = 3 * 1024 * 1024;

// const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const addSchema = Yup.object({
  title: Yup.string().required("Required"),
  logo: Yup.string().when("type.value", {
    is: (val: string) =>
      val === "2boxD" ||
      val === "2boxTD" ||
      val === "3boxD" ||
      val === "3boxTD",
    then: Yup.string().optional(),
    otherwise: Yup.string().required("Required"),
  }),
  // .test(
  //   "fileSize",
  //   "File too large",
  //   (value) => value && value.size <= FILE_SIZE
  // )
  // .test(
  //   "fileFormat",
  //   "Unsupported Format",
  //   (value) => value && SUPPORTED_FORMATS.includes(value.type)
  // ),
  isPro: Yup.boolean().required("Required"),
  prefix: Yup.string().optional(),
  suffix: Yup.string().optional(),
  androidPrefix: Yup.string().optional(),
  iosPrefix: Yup.string().optional(),
  extraLabel: Yup.boolean().required("Required"),
  extraImage: Yup.boolean().required("Required"),
  extraTitle: Yup.string().when("type.value", {
    is: (value: string) =>
      value === "3boxTD" ||
      value === "3box" ||
      value === "2boxTD" ||
      value === "2box",
    then: Yup.string().required("Required"),
    otherwise: Yup.string().optional(),
  }),

  extraDescription: Yup.string().when("type.value", {
    is: (value: string) =>
      value === "3box" ||
      value === "3boxD" ||
      value === "3boxTD" ||
      value === "3boxID" ||
      value === "2box" ||
      value === "2boxD" ||
      value === "2boxTD" ||
      value === "2boxID",
    then: Yup.string().required("Required"),
    otherwise: Yup.string().optional(),
  }),
  extraPlaceholder: Yup.string().when("extraLabel", {
    is: true,
    then: Yup.string().required("Required"),
    otherwise: Yup.string().optional(),
  }),
  placeholder: Yup.string().required("Required"),
  type: Yup.object().required("Required"),

  // key: Yup.string().optional(),
  category: Yup.array()
    .required("Required")
    .of(
      Yup.object().shape({
        value: Yup.string().required(),
      })
    )
    .min(1, "Select at least one Category"),
  forFreeUser: Yup.number().required("Required"),
  forPaidUser: Yup.number().required("Required"),
  skippedWords: Yup.string().optional(),
});

const LinkMasterEditDrawer: React.FC<Props> = ({ link, isOpen, onClose }) => {
  const { masterLinks, busy, message } = useLinksMaster();
  const [showType, setShowType] = useState("");
  const [showType1, setShowType1] = useState("");

  const dispatch = useDispatch();
  const { linkCategory } = useLinkCategories();

  const categoryOption = useMemo(
    () =>
      linkCategory.map((lc: Record<string, any>) => {
        return {
          label: lc.title,
          value: lc._id,
        };
      }),
    [linkCategory]
  );

  const typeOptions: any[] = useMemo(() => {
    return [
      { value: "text", label: "Text" },
      { value: "3box", label: "3 BOX Banner" },
      { value: "3boxTD", label: "3boxTD" },
      { value: "3boxD", label: "3boxD" },
      { value: "3boxID", label: "3boxID" },
      { value: "2box", label: "2 BOX Banner" },
      { value: "2boxTD", label: "2boxTD" },
      { value: "2boxD", label: "2boxD" },
      { value: "2boxID", label: "2boxID" },
      { value: "number", label: "Number" },
      { value: "links", label: "Contact Card" },
      { value: "Youtube Embedded", label: "Youtube Embedded" },
      { value: "Spotify Embedded", label: "Spotify Embedded" },
      { value: "email", label: "Email" },
      { value: "file", label: "File" },
      { value: "Tik Tok Embedded", label: "Tik Tok Embedded" },
    ];
  }, []);

  const initialValue = useMemo(() => {
    if (link) {
      return {
        title: link.title,
        logo: link.logo,
        isPro: link.isPro,
        prefix: link.prefix,
        suffix: link.suffix,
        androidPrefix: link.androidPrefix,
        iosPrefix: link.iosPrefix,
        placeholder: link.placeholder,
        extraLabel: link.extraLabel,
        extraImage: link.extraImage,
        extraTitle: link.extraTitle ? link.extraTitle : "",
        extraDescription: link.extraDescription ? link.extraDescription : "",
        extraPlaceholder: link.extraPlaceholder,
        skippedWords: link.skippedWords?.toString(),
        type: typeOptions.find((to) => to.value === link.type),
        // key: link.key,
        forFreeUser: link.maxLinks.forFreeUser,
        forPaidUser: link.maxLinks.forPaidUser,
        category: (link.category || []).map(
          (value: string | Record<string, any>) => ({
            value,
            label: categoryOption.find((cat: any) => value === cat.value).label,
          })
        ),
      };
    }
    return {
      title: "",
      logo: "",
      isPro: false,
      prefix: "",
      suffix: "",
      androidPrefix: "",
      iosPrefix: "",
      placeholder: "",
      skippedWords: null,
      extraLabel: false,
      extraImage: false,
      extraTitle: "",
      extraDescription: "",
      extraPlaceholder: "",
      type: { value: "" },
      // key: "",
      forFreeUser: "",
      forPaidUser: "",
      category: [],
    };
  }, [categoryOption, link, typeOptions]);

  const onSubmit = (values: Record<string, any>) => {
    let payload: Record<string, any> = {
      title: values.title,
      isPro: values.isPro,
      prefix: values.prefix,
      suffix: values.suffix,
      androidPrefix: values.androidPrefix,
      iosPrefix: values.iosPrefix,
      placeholder: values.placeholder,
      extraLabel: values.extraLabel,
      extraImage: values.extraImage,
      extraTitle: values.extraTitle ? values.extraTitle : "",
      extraDescription: values.extraDescription ? values.extraDescription : "",
      extraPlaceholder: values.extraPlaceholder,
      skippedWords: values.skippedWords,
      type: values.type.value,
      // key: values.key,
      logo: values.logo,
      maxLinks: {
        forFreeUser: values.forFreeUser,
        forPaidUser: values.forPaidUser,
      },
      category: values.category.map((item: any) => {
        return item.value;
      }),
    };
    payload.skippedWords = payload.skippedWords?.split(",");
    payload.skippedWords = payload.skippedWords?.map((item: string) => {
      if (item.trim() === "" || !item) {
        return null;
      }
      return item.trim();
    });

    payload.skippedWords = payload.skippedWords?.filter(
      (item: string) => item !== null
    );

    payload.skippedWords = payload.skippedWords?.sort(
      (a: string, b: string) => b.length - a.length
    );

    if (link) {
      const data = { payload, _id: link._id };
      dispatch({ type: REQUEST_UPDATE_MASTER_LINK, data });
    } else {
      dispatch({ type: REQUEST_CREATE_MASTER_LINK, payload });
    }
  };

  const toast = useToast();
  const links = useMemo(() => {
    return masterLinks;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (link) {
      const linkFromStore = masterLinks.find((ml: any) => ml._id === link._id);
      if (linkFromStore !== link) {
        toast({
          title: "Success.",
          description: "Link is updated.",
          status: "success",
          isClosable: true,
          position: "top-right",
        });
        onClose();
      }
    } else if (links.length !== masterLinks.length) {
      toast({
        title: "Success.",
        description: "Link is created.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      onClose();
    }
  }, [links, masterLinks, onClose, link, toast]);

  const [isDisabled, setDisabled] = useState(!!(link && link._id));

  // console.log(showType);
  return (
    <Formik
      initialValues={initialValue}
      validationSchema={addSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, errors, touched, submitForm }) => (
        <Form>
          <Drawer
            isOpen={isOpen}
            placement="right"
            size={"md"}
            onClose={onClose}
            closeOnOverlayClick={busy}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader backgroundColor={"gray.100"} alignItems={"stretch"}>
                <Flex>
                  {get(link, "title") || "Add link"}
                  <Spacer></Spacer>
                  {link && link._id && (
                    <IconButton
                      aria-label="update link"
                      key={"updateLinkButton"}
                      alignSelf={"end"}
                      onClick={() => {
                        setDisabled(!isDisabled);
                        if (link) {
                          setShowType(link.extraDescription ? link.type : "");
                        }
                      }}
                      icon={<MdModeEditOutline />}
                    ></IconButton>
                  )}
                </Flex>

                {message && (
                  <Alert status="error">
                    <AlertTitle>{message}</AlertTitle>
                  </Alert>
                )}
              </DrawerHeader>
              <DrawerBody>
                <Flex justifyContent={"center"} alignItems="center">
                  <ImageFormField
                    disabled={isDisabled || busy}
                    image={values["logo"]}
                    onSuccess={({ img: image }: { img: string }) => {
                      if (image) setFieldValue("logo", image);
                    }}
                  />
                  <Text color={"red"} ml="1">
                    {errors.logo ? (errors.logo as string) : undefined}
                  </Text>
                </Flex>
                <FormField
                  required={true}
                  disabled={isDisabled}
                  name="Name : "
                  fieldName="title"
                  type="text"
                  error={touched.title ? (errors.title as string) : undefined}
                />
                <Box paddingTop={1} paddingBottom={1}>
                  <FormControl isRequired>
                    <RadioGroup
                      onChange={(val) => {
                        setFieldValue("isPro", val === "true");
                      }}
                      value={values.isPro?.toString()}
                    >
                      <Stack direction="row">
                        <FormLabel>Pro :</FormLabel>
                        <Radio value={"true"}>PRO</Radio>
                        <Radio value={"false"}>FREE</Radio>
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Box>
                <FormField
                  disabled={isDisabled}
                  required={false}
                  name="Prefix : "
                  fieldName="prefix"
                  type="text"
                  error={errors.prefix ? (errors.prefix as string) : undefined}
                />
                <FormField
                  disabled={isDisabled}
                  required={false}
                  name="Suffix : "
                  fieldName="suffix"
                  type="text"
                  error={errors.suffix ? (errors.suffix as string) : undefined}
                />
                <FormField
                  disabled={isDisabled}
                  required={false}
                  name="Android Prefix : "
                  fieldName="androidPrefix"
                  type="text"
                  error={
                    errors.androidPrefix
                      ? (errors.androidPrefix as string)
                      : undefined
                  }
                />
                <FormField
                  disabled={isDisabled}
                  required={false}
                  name="IOS Prefix : "
                  fieldName="iosPrefix"
                  type="text"
                  error={
                    errors.iosPrefix ? (errors.iosPrefix as string) : undefined
                  }
                />
                <FormField
                  disabled={isDisabled}
                  required={true}
                  name="Place Holder : "
                  fieldName="placeholder"
                  type="text"
                  error={
                    errors.placeholder
                      ? (errors.placeholder as string)
                      : undefined
                  }
                />
                <FormField
                  disabled={isDisabled}
                  required={false}
                  name="Skipped words : "
                  fieldName="skippedWords"
                  placeholder="e.g.: https://, http://"
                  type="text"
                  error={
                    errors.skippedWords
                      ? (errors.skippedWords as string)
                      : undefined
                  }
                />

                <FormControl isRequired>
                  <Flex width="100%">
                    <HStack>
                      <FormLabel>Type : </FormLabel>
                      <Spacer></Spacer>
                      <p>{errors.type}</p>
                    </HStack>
                  </Flex>
                  <Select
                    value={values["type"]}
                    name="type"
                    isDisabled={isDisabled}
                    onChange={(event) => {
                      if (event) {
                      }
                      //@ts-ignore
                      setFieldValue("type", event);
                      setShowType(event.value);
                      setShowType1(event.value);
                    }}
                    options={typeOptions}
                    placeholder="Select a type"
                  />
                </FormControl>

                {showType === "3box" ||
                showType === "3boxTD" ||
                showType === "3boxD" ||
                showType === "3boxID" ||
                showType === "2box" ||
                showType === "2boxTD" ||
                showType === "2boxD" ||
                showType === "2boxID" ? (
                  <>
                    <Box my={2}>
                      <FormField
                        disabled={isDisabled}
                        required={true}
                        name="Extra Title : "
                        placeholder="Enter Extra Title"
                        fieldName="extraTitle"
                        type="text"
                        error={
                          errors.extraTitle
                            ? (errors.extraTitle as string)
                            : undefined
                        }
                      />
                      <FormField
                        disabled={isDisabled}
                        required={true}
                        name="Extra Description : "
                        placeholder="Enter Extra Description"
                        fieldName="extraDescription"
                        type="text"
                        error={
                          errors.extraDescription
                            ? (errors.extraDescription as string)
                            : undefined
                        }
                      />
                    </Box>
                  </>
                ) : (
                  ""
                )}

                <Box paddingTop={1} paddingBottom={1}>
                  <FormControl isRequired>
                    <RadioGroup
                      onChange={(val) => {
                        setFieldValue("extraLabel", val === "true");
                      }}
                      value={values.extraLabel?.toString()}
                    >
                      <Stack direction="row">
                        <FormLabel>Extra label :</FormLabel>

                        <Radio value={"true"}>Allow</Radio>
                        <Radio value={"false"}>Deny</Radio>
                        <Text color={"red"}>
                          {errors.extraLabel
                            ? (errors.extraLabel as string)
                            : undefined}
                        </Text>
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Box>

                {showType1 === "3boxD" ||
                showType1 === "3boxTD" ||
                showType1 === "2boxD" ||
                showType1 === "2boxTD" ? (
                  ""
                ) : (
                  <>
                    <Box paddingTop={1} paddingBottom={1}>
                      <FormControl isRequired>
                        <RadioGroup
                          onChange={(val) => {
                            setFieldValue("extraImage", val === "true");
                          }}
                          value={values.extraImage?.toString()}
                        >
                          <Stack direction="row">
                            <FormLabel>Extra Image :</FormLabel>
                            <Radio value={"true"}>Allow</Radio>
                            <Radio value={"false"}>Deny</Radio>
                            <Text color={"red"}>
                              {errors.extraImage
                                ? (errors.extraImage as string)
                                : undefined}
                            </Text>
                          </Stack>
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </>
                )}

                <FormField
                  disabled={isDisabled}
                  required={true}
                  name="Extra Place Holder : "
                  fieldName="extraPlaceholder"
                  type="text"
                  error={
                    errors.extraPlaceholder
                      ? (errors.extraPlaceholder as string)
                      : undefined
                  }
                />

                {/* <FormField
                  disabled={isDisabled}
                  required={false}
                  name="Key : "
                  fieldName="key"
                  type="text"
                  error={errors.key ? (errors.key as string) : undefined}
                /> */}
                <FormControl isRequired>
                  <Flex width="100%">
                    <HStack>
                      <FormLabel>Category : </FormLabel>
                      <Spacer></Spacer>
                      <Text color={"red"}>{errors.category}</Text>
                    </HStack>
                  </Flex>
                  <Select
                    isMulti={true}
                    isDisabled={isDisabled}
                    value={values["category"]}
                    name="category"
                    onChange={(event) => {
                      setFieldValue("category", event);
                    }}
                    options={categoryOption}
                    placeholder="Select a Category"
                  />
                </FormControl>
                <FormField
                  disabled={isDisabled}
                  required={true}
                  name="Free User : "
                  fieldName="forFreeUser"
                  type="number"
                  error={
                    errors.forFreeUser
                      ? (errors.forFreeUser as string)
                      : undefined
                  }
                />
                <FormField
                  disabled={isDisabled}
                  required={true}
                  name="Paid User : "
                  fieldName="forPaidUser"
                  type="number"
                  error={
                    errors.forPaidUser
                      ? (errors.forPaidUser as string)
                      : undefined
                  }
                />
              </DrawerBody>
              <DrawerFooter backgroundColor={"gray.100"}>
                <Button
                  variant="outline"
                  mr={3}
                  onClick={onClose}
                  disabled={busy}
                >
                  Close
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={submitForm}
                  disabled={busy || isDisabled}
                >
                  {link && link._id ? "Update" : "Save"}
                </Button>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </Form>
      )}
    </Formik>
  );
};
export default LinkMasterEditDrawer;
