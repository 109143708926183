import { useSelector } from "react-redux";
import { ILinksCategory } from "../../types/linksCategory";
import {
  REQUEST_LINK_CATEGORY,
  SET_LINK_CATEGORY,
  UPDATE_LINK_CATEGORY_FOR_LINK_CREATE,
} from "./linkCategoryActionType";

const initialState: {
  busy: boolean;
  message: string;
  linkCategory: ILinksCategory[];
} = {
  busy: false,
  message: "",
  linkCategory: [],
};

const linkCategories = (state = initialState, action: Record<string, any>) => {
  switch (action.type) {
    case REQUEST_LINK_CATEGORY:
      return {
        ...state,
        busy: true,
        message: "",
      };

    case SET_LINK_CATEGORY:
      return {
        ...state,
        busy: false,
        message: "",
        linkCategory: action.payload,
      };

    case UPDATE_LINK_CATEGORY_FOR_LINK_CREATE:
      return {
        ...state,
        busy: false,
        message: "",
        linkCategory: action.payload,
      };

    default:
      return state;
  }
};

export default linkCategories;
export function useLinkCategories() {
  return useSelector((state: Record<string, any>) => state.linkCategories);
}
