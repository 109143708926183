import { useSelector } from "react-redux";
import { get } from "lodash";

import {
  REQUEST_MASTER_TUTORIAL,
  REQUEST_CREATE_MASTER_TUTORIAL,
  REQUEST_UPDATE_MASTER_TUTORIAL,
  REQUEST_DELETE_MASTER_TUTORIAL,
  SET_MASTER_TUTORIAL,
  SUCCESS_CREATE_MASTER_TUTORIAL,
  SUCCESS_UPDATE_MASTER_TUTORIAL,
  SUCCESS_DELETE_MASTER_TUTORIAL,
  ERROR_GET_MASTER_TUTORIAL,
  ERROR_CREATE_MASTER_TUTORIAL,
  ERROR_UPDATE_MASTER_TUTORIAL,
  ERROR_DELETE_MASTER_TUTORIAL,
  SET_MESSAGE_MASTER_TUTORIAL,
} from "./tutorialActionTypes";
import { ITutorial } from "../../types/tutorial";

interface IState {
  busy: boolean;
  message: string;
  masterTutorials: ITutorial[];
}

const initialState: IState = {
  busy: false,
  message: "",
  masterTutorials: [],
};

const tutorials = (state = initialState, action: Record<string, any>) => {
  switch (action.type) {
    case REQUEST_MASTER_TUTORIAL:
    case REQUEST_CREATE_MASTER_TUTORIAL:
    case REQUEST_UPDATE_MASTER_TUTORIAL:
    case REQUEST_DELETE_MASTER_TUTORIAL:
      return {
        ...state,
        busy: true,
        message: "",
      };

    case SET_MESSAGE_MASTER_TUTORIAL:
      return {
        ...state,
        message: "",
      };

    case SET_MASTER_TUTORIAL:
      return {
        ...state,
        busy: false,
        message: "",
        masterTutorials: action.payload,
      };

    case SUCCESS_CREATE_MASTER_TUTORIAL:
      return {
        ...state,
        busy: false,
        message: "",
        masterTutorials: [...state.masterTutorials, action.payload],
      };

    case SUCCESS_DELETE_MASTER_TUTORIAL:
      return {
        ...state,
        busy: false,
        message: "",
        masterTutorials: state.masterTutorials.filter(
          (tutorial) => get(tutorial, "_id") !== action.payload
        ),
      };

    case SUCCESS_UPDATE_MASTER_TUTORIAL:
      return {
        ...state,
        busy: false,
        message: "",
        masterTutorials: state.masterTutorials.map((mp) => {
          return get(mp, "_id") === action.payload._id ? action.payload : mp;
        }),
      };

    case ERROR_GET_MASTER_TUTORIAL:
    case ERROR_CREATE_MASTER_TUTORIAL:
    case ERROR_UPDATE_MASTER_TUTORIAL:
    case ERROR_DELETE_MASTER_TUTORIAL:
      return {
        ...state,
        busy: false,
        message:
          action.payload || "Something happened wrong try again after sometime",
      };
    default:
      return state;
  }
};

export default tutorials;

export function useTutorialsMaster() {
  return useSelector((state: Record<string, any>) => state.tutorials);
}
