import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Center,
  CloseButton,
  Flex,
  HStack,
  IconButton,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { REQUEST_LINK_CATEGORY } from "../../store/linkCategory/linkCategoryActionType";
import { useLinkCategories } from "../../store/linkCategory/reducer";
import { ILinks } from "../../types/links";
import { ILinksCategory } from "../../types/linksCategory";
import styles from "./LinksCategoryMasterTable.module.css";
import { MdModeEditOutline } from "react-icons/md";

import { useState } from "react";
import LinkCategoryModal from "../modals/LinkCategoryModal";

const LinksCategoryMasterTable: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const [catData, setCatData] = useState<ILinksCategory>();
  const { busy, message, linkCategory } = useLinkCategories();
  if (!busy && !(linkCategory || []).length) {
    dispatch({ type: REQUEST_LINK_CATEGORY });
  }

  if (!(linkCategory || []).length) {
    return (
      <>
        <p>Loading...</p>
      </>
    );
  }
  return (
    <>
      {message !== "" ? (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>Your browser is outdated!</AlertTitle>
          <AlertDescription>
            Your Chakra experience may be degraded.
          </AlertDescription>
          <CloseButton position="absolute" right="8px" top="8px" />
        </Alert>
      ) : (
        ""
      )}
      <div>
        {linkCategory.map((cat: ILinksCategory, index: number) => {
          return (
            <div
              style={{
                width: "95vw",
                maxWidth: "95vw",
                top: "10vh ",
                left: "5vw",
                position: "relative",
                paddingBottom: "100px",
              }}
            >
              <Box w="95%">
                <Flex w="100%" bg="#3a8476">
                  <HStack>
                    <Box p={4} color="white" key={cat.title}>
                      {cat.title}
                    </Box>
                  </HStack>
                  <Spacer></Spacer>
                  <HStack>
                    <Center>
                      <IconButton
                        mr={4}
                        aria-label="update link"
                        key={"updateLinkButton"}
                        alignSelf={"end"}
                        onClick={() => {
                          onOpen();
                          setCatData(cat as ILinksCategory);
                        }}
                        icon={<MdModeEditOutline />}
                      ></IconButton>
                    </Center>
                  </HStack>
                </Flex>
                <Box p={4} className={styles.icons}>
                  {cat.links.map((i) => {
                    const item = i as ILinks;
                    return (
                      <div className={styles.socialIconDivDrag}>
                        <div></div>
                        <img
                          src={item.logo}
                          className={styles.socialIcon}
                          alt={item.logo}
                        />
                        <p>{item.title}</p>
                      </div>
                    );
                  })}
                </Box>
              </Box>
            </div>
          );
        })}

        {isOpen && (
          <LinkCategoryModal
            isOpen={isOpen}
            onClose={onClose}
            catData={catData}
          />
        )}
      </div>
    </>
  );
};

export default LinksCategoryMasterTable;
