import axios from "axios";
import { get } from "lodash";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
    REQUEST_MASTER_MOBILE_COUPON,
    REQUEST_CREATE_MASTER_MOBILE_COUPON,
    REQUEST_UPDATE_MASTER_MOBILE_COUPON,
    SET_MASTER_MOBILE_COUPON,
    SUCCESS_CREATE_MASTER_MOBILE_COUPON,
    SUCCESS_UPDATE_MASTER_MOBILE_COUPON,
    ERROR_GET_MASTER_MOBILE_COUPON,
    ERROR_CREATE_MASTER_MOBILE_COUPON,
    ERROR_UPDATE_MASTER_MOBILE_COUPON,
} from "./mobilecouponActionTypes"

function* requestMasterMobileCoupon(action: Record<string, any>): any {
    try {
        const result: any = yield call(getMobileCouponMaster);
        yield put({ type: SET_MASTER_MOBILE_COUPON, payload: result.data });
        // console.warn(result.data);
        
    } catch (error: any) {
        console.log(error);
        let message =
            "Something went wrong, please try again after some time or Refresh the Page.";
        if (get(error, "response.status") === 500) {
            message = "Something happened wrong try again after sometime.";
        } else if (get(error, "response.status") === 422) {
            message = error.response.data.message || "please provide valid contain";
        } else if (get(error, "response.status") === 415) {
            message = error.response.data.message;
        }
        yield put({ type: ERROR_GET_MASTER_MOBILE_COUPON, payload: message });
    }
}

function* requestUpdateMasterMobileCoupon(action: Record<string, any>): any {
    try {
        const result = yield call(
            updateMasterMobileCoupon,
            action.data.payload,
            action.data._id
        )
        yield put({
            type: SUCCESS_UPDATE_MASTER_MOBILE_COUPON,
            payload: result.data,
        });
    } catch (error: any) {
        console.log(error);
        let message =
            "Something went wrong, please try again after some time or Refresh the Page.";
        if (get(error, "response.status") === 500) {
            message = "Something happened wrong try again after sometime.";
        } else if (get(error, "response.status") === 422) {
            message = error.response.data.message || "please provide valid contain";
        } else if (get(error, "response.status") === 415) {
            message = error.response.data.message;
        }
        yield put({ type: ERROR_UPDATE_MASTER_MOBILE_COUPON, payload: message });
    }
}

function* requestCreateMasterMobileCoupon(action: Record<string, any>): any {
    try {
        const result = yield call(createMasterMobileCoupon, action.payload);
        yield put({
            type: SUCCESS_CREATE_MASTER_MOBILE_COUPON,
            payload: result.data,
        });
        // update relevant category as well
    } catch (error: any) {
        console.log(error);
        let message =
            "Something went wrong, please try again after some time or Refresh the Page.";
        if (get(error, "response.status") === 500) {
            message = "Something happened wrong try again after sometime.";
        } else if (get(error, "response.status") === 422) {
            message = error.response.data.message || "please provide valid contain";
        } else if (get(error, "response.status") === 415) {
            message = error.response.data.message;
        }
        yield put({ type: ERROR_CREATE_MASTER_MOBILE_COUPON, payload: message });
    }
}


export function getMobileCouponMaster() {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/mobileCoupon`,
        withCredentials: true,
    });
}

export function updateMasterMobileCoupon(payload: Record<string, any>, _id: string) {
    return axios({
        method: "patch",
        url: `${process.env.REACT_APP_BASE_URL}/mobileCoupon/${_id}`,
        data: payload,
        withCredentials: true,
    });
}

export function createMasterMobileCoupon(payload: Record<string, any>) {
    return axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/mobileCoupon`,
        data: payload,
        withCredentials: true,
    });
}


const mobilecouponMasterSaga = function* () {
    yield all([
        takeLatest(REQUEST_MASTER_MOBILE_COUPON, requestMasterMobileCoupon),
        takeLatest(REQUEST_UPDATE_MASTER_MOBILE_COUPON, requestUpdateMasterMobileCoupon),
        takeLatest(REQUEST_CREATE_MASTER_MOBILE_COUPON, requestCreateMasterMobileCoupon),
    ])
}

export default mobilecouponMasterSaga;