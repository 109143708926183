export const REQUEST_MASTER_PROMOCODE = "REQUEST_MASTER_PROMOCODE";
export const SET_MASTER_PROMOCODE = "SET_MASTER_PROMOCODE";
export const ERROR_GET_MASTER_PROMOCODE = "ERROR_GET_MASTER_PROMOCODE";

export const REQUEST_CREATE_MASTER_PROMOCODE = "REQUEST_CREATE_MASTER_PROMOCODE";
export const SUCCESS_CREATE_MASTER_PROMOCODE = "SUCCESS_CREATE_MASTER_PROMOCODE";
export const ERROR_CREATE_MASTER_PROMOCODE = "ERROR_CREATE_MASTER_PROMOCODE";

export const REQUEST_UPDATE_MASTER_PROMOCODE = "REQUEST_UPDATE_MASTER_PROMOCODE";
export const SUCCESS_UPDATE_MASTER_PROMOCODE = "SUCCESS_UPDATE_MASTER_PROMOCODE";
export const ERROR_UPDATE_MASTER_PROMOCODE = "ERROR_UPDATE_MASTER_PROMOCODE";


export const SET_MESSAGE_MASTER_PROMOCODE = "SET_MESSAGE_MASTER_PROMOCODE";